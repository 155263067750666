import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './global.scss';
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import Authentication from './scenes/authentication';
import LoginForm from './scenes/authentication/login';
import Home from './scenes/home';
import { apolloClient } from '@utils/wire';
import { GlobalContext } from './context';
import useToggle from 'react-use/lib/useToggle';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ManageRealEstate from './scenes/home/manageRealEstate';
import LoadingScreen from './scenes/loadingScreen';
import NeedApproval from './scenes/home/manageRealEstate/needApproval';
import ManageOrganization from './scenes/home/manageOrganization';
import PermissionGroups from './scenes/home/manageOrganization/permissionGroups';
import ManageUser from './scenes/home/manageUser';
import ManageUserB2B from './scenes/home/manageUser/b2b';
import CreateB2BUserAccount from './scenes/home/createB2BUserAccount';
import ManageProduct from './scenes/home/manageProduct';
import Product from './scenes/home/manageProduct/product';
import ProductPosted from './scenes/home/manageProduct/productPosted';
import CreateB2BRealEstate from './scenes/home/createB2BRealEstate';
import AddProductInformation from './scenes/home/createB2BRealEstate/addProductInformation';
import CheckProduct from './scenes/home/createB2BRealEstate/checkProduct';
import PublishProduct from './scenes/home/publishProduct';
import ProductDetail from './scenes/home/manageProduct/productDetail';
import ManageEmployee from './scenes/home/manageEmployee';
import CreateEmployeeAccount from './scenes/home/manageEmployee/addEmployee';
import ManageCompanyEmployee from './scenes/home/manageEmployee/listEmployee';
import Project from './scenes/home/manageProject/listProject';
import ManageProject from './scenes/home/manageProject';
import CreatePermissionGroup from './scenes/home/manageOrganization/createPermissionGroup';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import Dashboard from './scenes/dashboard/dashboard';
import ManageFeedback from 'scenes/home/manageFeedback/feedback';

function Layout() {
  const token = localStorage.getItem('silverBullet');
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useToggle(null),
    [ready, setReady] = useState(false);
  const [id, setId] = useState('');
  const location = useLocation();
  const { permissions } = useGetMe();
  useEffect(() => {
    if (!token) {
      setIsLogin(false);
      navigate('/auth/login');
    } else {
      setIsLogin(true);
      if (location.pathname === '/') {
        if (permissions?.some(p => p.id === PermissionIds.ViewEmployeeList)) {
          navigate('/employee/company');
        } else if (
          permissions?.some(p => p.id === PermissionIds.ViewProductList)
        ) {
          navigate('/manageProduct/product');
        } else {
          navigate('/dashboard');
        }
      }
    }
  }, [token]);
  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 2000);
  }, [setReady]);
  return (
    <GlobalContext.Provider
      value={React.useMemo(
        () => ({ isLogin, setIsLogin, id, setId }),
        [isLogin, setIsLogin, id, setId]
      )}
    >
      {ready ? (
        <div style={{ flex: 1 }}>
          <Outlet />
        </div>
      ) : (
        <LoadingScreen />
      )}
    </GlobalContext.Provider>
  );
}

const routes = [
  {
    element: <Layout />,
    children: [
      {
        path: '/auth',
        element: <Authentication />,
        children: [
          {
            path: '/auth/login',
            element: <LoginForm />,
          },
        ],
      },
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/',
        element: <Home />,
        children: [
          {
            path: '/manageOrganization',
            element: <ManageOrganization />,
            children: [
              {
                path: '/manageOrganization/organization-chart',
                element: <></>,
              },
              {
                path: '/manageOrganization/permission-groups',
                element: <PermissionGroups />,
              },
              {
                path: '/manageOrganization/permission-groups/create',
                element: <CreatePermissionGroup />,
              },
              {
                path: '/manageOrganization/permission-groups/edit',
                element: <CreatePermissionGroup />,
              },
            ],
          },
          {
            path: '/manageRealEstate',
            element: <ManageRealEstate />,
            children: [
              {
                path: '/manageRealEstate/needApproval',
                element: <NeedApproval />,
              },
              {
                path: '/manageRealEstate/approved',
                element: <NeedApproval />,
              },
              {
                path: '/manageRealEstate/denied',
                element: <NeedApproval />,
              },
              {
                path: '/manageRealEstate/feedbackValuation',
                element: <ManageFeedback />,
              },
            ],
          },
          {
            path: '/manageUser',
            element: <ManageUser />,
            children: [
              {
                path: '/manageUser/b2b',
                element: <ManageUserB2B />,
              },
              {
                path: '/manageUser/b2c',
                element: <ManageUserB2B />,
              },
            ],
          },
          {
            path: '/createB2BUserAccount',
            element: <CreateB2BUserAccount />,
          },
          {
            path: '/editB2BUserAccount/:id',
            element: <CreateB2BUserAccount />,
          },
          {
            path: '/createB2BRealEstate',
            element: <CreateB2BRealEstate />,
            children: [
              {
                path: '/createB2BRealEstate/check-product',
                element: <CheckProduct />,
              },
              {
                path: '/createB2BRealEstate/add-product-information',
                element: <AddProductInformation />,
              },
            ],
          },
          {
            path: '/manageProduct',
            element: <ManageProduct />,
            children: [
              {
                path: '/manageProduct/product',
                element: <Product />,
              },
              {
                path: '/manageProduct/my-product',
                element: <Product />,
              },
              {
                path: '/manageProduct/product/waiting-for-approval',
                element: <Product />,
              },
              {
                path: '/manageProduct/product/hidden',
                element: <Product />,
              },
              {
                path: '/manageProduct/product/deleted',
                element: <Product />,
              },
              {
                path: '/manageProduct/product/rejected',
                element: <Product />,
              },
              {
                path: '/manageProduct/details/:id',
                element: <ProductDetail />,
              },
              {
                path: '/manageProduct/product-posted',
                element: <ProductPosted />,
              },
              {
                path: '/manageProduct/product/premium',
                element: <ProductPosted />,
              },
              {
                path: '/manageProduct/product/authorize',
                element: <ProductPosted />,
              },
            ],
          },
          {
            path: '/publish',
            element: <PublishProduct />,
            children: [
              {
                path: '/publish/product',
                element: <PublishProduct />,
              },
              {
                path: '/publish/authorized',
                element: <PublishProduct />,
              },
              {
                path: '/publish/premium',
                element: <PublishProduct />,
              },
            ],
          },
          {
            path: '/employee',
            element: <ManageEmployee />,
            children: [
              {
                path: '/employee/company',
                element: <ManageCompanyEmployee />,
              },
              {
                path: '/employee/create',
                element: <CreateEmployeeAccount />,
              },
              {
                path: '/employee/edit',
                element: <CreateEmployeeAccount />,
              },
            ],
          },
          {
            path: '/project',
            element: <ManageProject />,
            children: [
              {
                path: '/project/company',
                element: <Project />,
              },
            ],
          },
        ],
      },
    ],
  },
];

const renderRoutes = routeConfigs => {
  return routeConfigs.map(route => {
    return (
      <Route
        key={`route-${route.path}`}
        path={route.path}
        element={route.element}
        exact
      >
        {route.children && renderRoutes(route.children)}
      </Route>
    );
  });
};
const themeMui = createTheme({
  zIndex: {
    modal: 1250,
  },
});

const theme = extendTheme({});
ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <ThemeProvider theme={themeMui}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Routes>{renderRoutes(routes)}</Routes>
        </BrowserRouter>
      </ChakraProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
