import React from 'react';
import UserProfileDropdown from '@components/header/components/userProfileDropdown';
import HeaderNavigation from '@components/header/components/headerNavigation';
import { useNavigate } from 'react-router-dom';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
export default function Header() {
  const navigate = useNavigate();
  const { permissions } = useGetMe();
  return (
    <div
      style={{ zIndex: 1000 }}
      id="header"
      className="w-full h-60px bg-blue-main flex flex-row items-center px-4 py-3 justify-between sticky top-0"
    >
      <div className="flex flex-row items-center space-x-3 pr-5">
        <img
          onClick={() => navigate('/dashboard')}
          alt="logo"
          src="/svg/logoHeader.svg"
          className="bg-contain cursor-pointer"
        />
        <HeaderNavigation />
      </div>
      <div className="flex flex-row items-center space-x-5 sm:space-x-3">
        {permissions?.some(p => p.id === PermissionIds.PostProduct) && (
          <div className="flex flex-row border items-center border-white bg-blue-800 rounded-full px-2 py-1 cursor-pointer mr-4">
            <p
              onClick={() => navigate('/publish/product')}
              className="text-white font-medium mr-1 text-14 sm:text-13 pl-1"
            >
              Đăng tin rao
            </p>
            <img alt="plus-icon" src="/svg/plus-icon.svg" />
          </div>
        )}
        <img
          alt="chat-ico"
          src="/svg/chat-icon.svg"
          className="cursor-pointer"
        />
        <img
          alt="noti-ico"
          src="/svg/noti-icon.svg"
          className="cursor-pointer"
        />
        <UserProfileDropdown />
      </div>
    </div>
  );
}
