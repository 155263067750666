import React, { useRef, useState } from 'react';
import Header from './components/header';
import SwitchAccountType from './components/switchAccountType';
import { FormProvider, useForm } from 'react-hook-form';
import { blankState } from './configs';
import LoginDetails from './components/loginDetails';
import LeftCustomerInfo from './components/infoInput/leftCustomerInfo';
import RightAdministratorInfo from './components/infoInput/rightAdministratorInfo';
import ContractDocuments from './components/contractDocuments';
import Submit from './components/submit';
import { HoChiMinhId } from '../../../constant';
import isEqual from 'lodash/isEqual';
import { useNavigate, useParams } from 'react-router';
import { useGetUserInfo } from '@utils/hooks/manageUserB2B/useGetUserDetail';
import { useGetCompanyInfo } from '@utils/hooks/manageUserB2B/useCompanyInfo';
import { useDeepCompareEffect } from 'react-use';
import moment from 'moment';
import ConfirmRegister from '../../../modals/confirmRegisterAccountModal';
import MyModalV2 from '@components/modalV2/myModalV2';

export default function CreateB2BUserAccount() {
  const form = useForm({
    defaultValues: {
      ...blankState,
    },
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { watch } = form;
  const { ...state } = watch();
  const userInitialValue = useRef({});
  const { id } = useParams();
  const isUpdate = location.pathname.includes('edit');
  const shouldRenderPrompt = !isEqual(
    state,
    isUpdate ? userInitialValue.current : blankState
  );
  const { data: userData } = useGetUserInfo(id);
  const { data: companyData } = useGetCompanyInfo(userData?.company);
  const mergedDate = {
    ...userData,
    companyName: companyData?.name,
    companyProvince: companyData?.province?.id,
    companyDistrict: companyData?.district?.id,
    companyWard: companyData?.ward?.id,
    companyStreet: companyData?.street?.id,
    companyHouseNumber: companyData?.houseNumber,
    logo: companyData?.logo,
    taxIdOrIdNumber: companyData?.taxId,
  };
  useDeepCompareEffect(() => {
    if (isUpdate && mergedDate) {
      const remoteState = {
        isPersonal: mergedDate?.isPersonal,
        username: mergedDate?.username ?? '',
        password: mergedDate?.password ?? '',
        confirmPassword: mergedDate?.confirmPassword ?? '',
        logo: mergedDate?.logo ?? '',
        avatar: mergedDate?.avatar ?? '',
        companyName: mergedDate?.companyName ?? '',
        taxIdOrIdNumber: mergedDate?.taxIdOrIdNumber ?? '',
        companyProvince: mergedDate?.companyProvince ?? HoChiMinhId,
        companyDistrict: mergedDate?.companyDistrict ?? '',
        companyWard: mergedDate?.companyWard ?? '',
        companyStreet: mergedDate?.companyStreet ?? '',
        companyHouseNumber: mergedDate?.companyHouseNumber ?? '',
        fullName: mergedDate?.fullName ?? '',
        gender: mergedDate?.gender ?? '',
        dateOfBirth: mergedDate?.dateOfBirth
          ? moment(mergedDate?.dateOfBirth).unix()
          : '',
        idImages: mergedDate?.idImages ?? [],
        attachments: mergedDate?.attachments ?? [],
        idNumber: mergedDate?.idNumber ?? '',
        phone: mergedDate?.phone ?? '',
        secondPhone: mergedDate?.secondPhone ?? '',
        email: mergedDate?.email ?? '',
        province: mergedDate?.province?.id ?? HoChiMinhId,
        district: mergedDate?.district?.id ?? '',
        ward: mergedDate?.ward?.id ?? '',
        street: mergedDate?.street?.id ?? '',
        houseNumber: mergedDate?.houseNumber ?? '',
        mediaUrls: mergedDate?.mediaUrls,
      };
      form.reset({ ...remoteState });
      userInitialValue.current = remoteState;
    }
  }, [mergedDate]);

  return (
    <FormProvider {...form}>
      <div className="w-full h-screen">
        <div
          className="w-full h-full mt-1 bg-white px-4 pt-5"
          style={{
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          <Header isEdit={isUpdate} />
          <SwitchAccountType />
          <LoginDetails isUpdate={isUpdate} />
          <div className="w-full flex flex-row mt-4 space-x-4">
            <LeftCustomerInfo />
            <RightAdministratorInfo isEdit={isUpdate} />
          </div>
          <ContractDocuments
            isEdit={isUpdate}
            wrapperClass="border border-gray-300 px-4"
          />
          <Submit
            onBack={
              shouldRenderPrompt ? () => setShowModal(true) : () => navigate(-1)
            }
            idAdmin={id}
            isUpdate={isUpdate}
          />
        </div>
      </div>
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ConfirmRegister
          isEdit={isUpdate}
          onBack={() => navigate(-1)}
          onContinue={() => setShowModal(false)}
        />
      </MyModalV2>
    </FormProvider>
  );
}
