import React, { useEffect, useState } from 'react';
import Modal, { ModalProps } from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import styles from './ModalWrapper.module.scss';
import Fade from '@mui/material/Fade';

type Props = {
  modalClass?: string,
  className?: string,
  hideClose?: boolean,
  onClose?: Function,
} & ModalProps;

export default function MyModalV2(props: Props) {
  const { open, children, onClose, className, style, modalClass, hideClose } =
    props;
  const [renderChildren, setRenderChildren] = useState(false);
  useEffect(() => {
    if (open && !renderChildren) {
      setRenderChildren(true);
    }
  }, [open]);
  return (
    <Modal
      disableEnforceFocus={true}
      disableAutoFocus={true}
      className={`${styles.modal} ${modalClass}`}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div style={style} className={`${className} ${styles.modalInner}`}>
          {!hideClose && (
            <img
              style={{ zIndex: 1000 }}
              onClick={onClose}
              alt="ico"
              src="/svg/ico-close.svg"
              className="w-6 h-6 absolute right-2 top-2 cursor-pointer"
            />
          )}
          {renderChildren && children}
        </div>
      </Fade>
    </Modal>
  );
}
