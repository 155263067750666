import React from 'react';
import HorizontalBar from '@components/horizontalBar';
import RenderIconComment from '../scenes/home/manageProduct/componentIconTable/renderIconComment';

type Props = {
  currentTab?: string,
  setCurrentTab?: Function,
  configs?: Array,
  setSameButton?: Function,
};

export default function SwitchInner(props: Props) {
  const { currentTab, setCurrentTab, configs, setSameButton } = props;
  return (
    <div
      style={{
        padding: '25px 30px 10px',
      }}
      className="flex-1 flex flex-row space-x-6 border-b border-gray-300"
    >
      {configs
        ?.filter(e => !e.isHide)
        ?.map((item, index) => {
          const isActive = currentTab === item.key;
          return (
            <div
              key={index}
              className="flex flex-col relative justify-center items-center"
            >
              <div
                style={{ color: isActive ? '#2459ad' : '#80888F' }}
                onClick={() => {
                  setCurrentTab(item.key);
                  location.pathname.includes('manageProduct')
                    ? setSameButton(false)
                    : null;
                }}
                key={index}
                className={`cursor-pointer text-13 font-medium flex flex-row items-center`}
              >
                {item.value}
                {item?.hasNewUpdate && <RenderIconComment />}
              </div>
              <HorizontalBar open={isActive} className="absolute -bottom-2.5" />
            </div>
          );
        })}
    </div>
  );
}
