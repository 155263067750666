export const HoChiMinhId = '642a83c4176b5d214073f27c';
export const DocumentsMaxSize2MB = 2097152;
export const HoChiMinhName = 'Thành phố Hồ Chí Minh';
export const NeedApprovalLocalStorageKey = 'needApprovalTableField';
export const ApprovedLocalStorageKey = 'approvedTableField';
export const DeniedLocalStorageKey = 'deniedTableField';
export const maxLength = {
  email: 68,
  taxId: 15,
  password: 32,
  name: 118,
  phoneNumber: 10,
  bankCode: 20,
  width: 6,
  length: 8,
  rear: 6,
  violatedLand: 14,
  constructionArea: 16,
  developerCode: 20,
  otpCode: 6,
  idNumber: 12,
  floor: 168,
  alleyWidth: 100,
  price: 20,
  commissionDetail: 88,
  postContent: 3168,
  maxPostDays: 365,
};
export const ProductStorageKey = 'productTableField';
export const MyProductStorageKey = 'myProductTableField';
export const ApprovedProductStorageKey = 'approvedProductTableField';
export const HiddenProductStorageKey = 'hiddenProductTableField';
export const DeletedProductStorageKey = 'deletedProductTableFiled';
export const PostedProductStorageKey = 'postedProductTableFiled';
export const PremiumProductStorageKey = 'premiumProductTableFiled';
export const AuthorizeProductStorageKey = 'authorizeProductTableField';
export const PermissionGroupStorageKey = 'permissionGroupTableField';

export const omitFields = {
  townhouse: [
    'hotelStar',
    'warehouseRoomCount',
    'buildingType',
    'block',
    'roomNumber',
    'shophouseField',
    'warehouseType',
    'onFloor',
  ],
  villa: [
    'hotelStar',
    'warehouseRoomCount',
    'buildingType',
    'onFloor',
    'shophouseField',
    'warehouseType',
  ],
  building: [
    'hotelStar',
    'warehouseRoomCount',
    'onFloor',
    'shophouseField',
    'warehouseType',
  ],
  hotel: [
    'warehouseRoomCount',
    'onFloor',
    'buildingType',
    'shophouseField',
    'warehouseType',
  ],
  warehouse: ['onFloor', 'buildingType', 'hotelStar', 'shophouseField'],
  apartment: [
    'buildingType',
    'hotelStar',
    'warehouseRoomCount',
    'shophouseField',
    'warehouseType',
  ],
  shophouse: [
    'bedRoomArea',
    'buildingType',
    'hotelStar',
    'warehouseRoomCount',
    'warehouseType',
  ],
  penthouse: [
    'bedRoomArea',
    'buildingType',
    'hotelStar',
    'warehouseRoomCount',
    'shophouseField',
    'warehouseType',
  ],
  condotel: [
    'bedRoomArea',
    'buildingType',
    'hotelStar',
    'warehouseRoomCount',
    'shophouseField',
    'warehouseType',
  ],
  land: ['warehouseType', 'buildingType'],
  motel: [
    'buildingType',
    'warehouseType',
    'shophouseField',
    'roomNumber',
    'hotelStar',
    'hasSeaView',
    'block',
    'bedRoomArea',
  ],
};
export const POST_TYPE_CONFIG = {
  1: {
    title: 'màu đỏ.',
    value: 'Tin Vip đặc biệt',
    className: 'text-red-500',
  },
  2: {
    title: 'màu cam.',
    value: 'Tin Vip 1',
    className: 'text-yellow-500',
  },
  3: {
    title: 'màu đen.',
    value: 'Tin thường',
    className: 'text-black',
  },
};
export const FEES_CONFIG = {
  1: {
    title: '3.000',
  },
  2: {
    title: '2.000',
  },
  3: {
    title: '1.000',
  },
};
export const defaultPositionName = {
  leader: 'Người đứng đầu',
  manager: 'Quản lý',
  secretary: 'Thư ký',
  employee: 'Nhân viên',
};
export const rolePermissions = {
  create: 'Quyền Tạo',
  approve: 'Quyền Duyệt',
};
export const randomImgIds = [
  '614c087a99815400306dbab4',
  '614c087d99815400306dbab5',
  '614c087f99815400306dbab6',
  '614c088299815400306dbab7',
  '614c088699815400306dbab8',
  '614c088d99815400306dbab9',
  '614c090499815400306dbaba',
  '614c090499815400306dbabb',
  '614c090699815400306dbabc',
  '614c090999815400306dbabd',
  '614c090c99815400306dbabe',
  '614c091099815400306dbabf',
  '614c094d99815400306dbac0',
  '614c094f99815400306dbac1',
  '614c095299815400306dbac2',
  '614c095599815400306dbac3',
  '614c095799815400306dbac4',
  '614c095999815400306dbac5',
  '614c099399815400306dbac6',
  '614c099699815400306dbac7',
  '614c099999815400306dbac8',
  '614c099c99815400306dbac9',
  '614c099f99815400306dbaca',
  '614c09a199815400306dbacb',
  '614c745b99815400306dbacf',
  '614c745d99815400306dbad0',
  '614c745f99815400306dbad1',
  '614c746199815400306dbad2',
  '614c746499815400306dbad3',
  '614c746699815400306dbad4',
  '614dfc5a99815400306dbb02',
  '614dfc5d99815400306dbb03',
  '614dfc6099815400306dbb04',
  '614dfc6399815400306dbb05',
  '614dfc6799815400306dbb06',
  '614dfc6d99815400306dbb07',
  '614dfcd399815400306dbb08',
  '614dfcd599815400306dbb09',
  '614dfcd699815400306dbb0a',
  '614dfcd999815400306dbb0b',
  '614dfcdb99815400306dbb0c',
  '614dfcdd99815400306dbb0d',
  '614dfd2299815400306dbb0e',
  '614dfd2499815400306dbb0f',
  '614dfd2699815400306dbb10',
  '614dfd2899815400306dbb11',
  '614dfd2999815400306dbb12',
  '614dfd2b99815400306dbb13',
  '614dfd5d99815400306dbb14',
  '614dfd5f99815400306dbb15',
  '614dfd6199815400306dbb16',
  '614dfd6399815400306dbb17',
  '614dfd6499815400306dbb18',
  '614dfd6699815400306dbb19',
  '614dfd9a99815400306dbb1a',
  '614dfd9c99815400306dbb1b',
  '614dfd9d99815400306dbb1c',
  '614dfd9f99815400306dbb1d',
  '614dfda199815400306dbb1e',
  '614dfda299815400306dbb1f',
  '614dfde299815400306dbb20',
  '614dfde499815400306dbb21',
  '614dfde599815400306dbb22',
  '614dfde799815400306dbb23',
  '614dfde999815400306dbb24',
  '614dfdeb99815400306dbb25',
  '614dfe3d99815400306dbb27',
  '614dfe3e99815400306dbb28',
  '614dfe4099815400306dbb29',
  '614dfe4299815400306dbb2a',
  '614dfe4499815400306dbb2b',
  '614dfe4699815400306dbb2c',
  '614dfe7a99815400306dbb2d',
  '614dfe7c99815400306dbb2e',
  '614dfe7d99815400306dbb2f',
  '614dfe7f99815400306dbb30',
  '614dfe8199815400306dbb31',
  '614dfe8299815400306dbb32',
  '614dfeba99815400306dbb33',
  '614dfebc99815400306dbb34',
  '614dfebe99815400306dbb35',
  '614dfec099815400306dbb36',
  '614dfec199815400306dbb37',
  '614dfec399815400306dbb38',
  '614dff2799815400306dbb39',
  '614dff2899815400306dbb3a',
  '614dff2a99815400306dbb3b',
  '614dff2c99815400306dbb3c',
  '614dff2e99815400306dbb3d',
  '614dff3099815400306dbb3e',
  '614dff6899815400306dbb3f',
  '614dff6b99815400306dbb40',
  '614dff6d99815400306dbb41',
  '614dff6f99815400306dbb42',
  '614dff7099815400306dbb43',
  '614dff7299815400306dbb44',
  '614dffb599815400306dbb45',
  '614dffb799815400306dbb46',
  '614dffb999815400306dbb47',
  '614dffbb99815400306dbb48',
  '614dffbd99815400306dbb49',
  '614dffbf99815400306dbb4a',
];
export const PAGINATION_LIMIT = 30;
export const PAGINATION_MAX_PAGE_VALID = 71582789;
