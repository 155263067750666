import { FormProvider, useForm } from 'react-hook-form';
import { blankState } from './configs';
import Header from './components/header';
import CustomerInfoInput from './components/contractinput';
import ContractDocuments from './components/documents';
import Submit from './components/submit';
import React, { useCallback, useState } from 'react';
import isEqual from 'lodash/isEqual';
import ConfirmModal from '../confirmModal';
import MyModalV2 from '@components/modalV2/myModalV2';
import { useGetContractDetail } from '@utils/hooks/manageContract/useGetContractDetail';
import { useDeepCompareEffect } from 'react-use';
import moment from 'moment';

type Props = {
  isNew?: Boolean,
  onClose?: Function,
  idAdmin?: String,
  onSuccess?: Function,
  tab?: String,
  contractId?: String,
  onCloseDetailModal?: Function,
  b2bUserData?: Object,
  listAttachment?: Array,
};

export default function CreateContract({
  isNew,
  onClose,
  idAdmin,
  onSuccess,
  tab,
  contractId,
  onCloseDetailModal,
  b2bUserData,
  listAttachment,
}: Props) {
  const form = useForm({
    defaultValues: {
      ...blankState,
    },
    mode: 'onChange',
  });
  const [showModal, setShowModal] = useState(false);
  const isChanged = isEqual(blankState, form.watch());
  const { contractDetail } = useGetContractDetail({ id: contractId });
  let isUpgrade = false;
  contractId && !isNew ? (isUpgrade = true) : isUpgrade;
  const mergedData = {
    ...contractDetail,
  };
  const oldData = {
    name: mergedData?.name,
    note: mergedData?.note,
    contractDate: moment(mergedData?.contractDate).unix(),
    dueDate: mergedData?.dueDate,
    totalMember: mergedData?.totalMember,
    discount: mergedData?.discount,
    attachments: listAttachment ?? [],
    system: mergedData?.system,
  };
  useDeepCompareEffect(() => {
    if (mergedData && isUpgrade) {
      form.reset(oldData);
    }
  }, [mergedData]);
  const isUpgradeChanged = isEqual(oldData, form.watch());
  const onCloseModal = useCallback(() => {
    if ((!isChanged && isNew) || (!isUpgradeChanged && isUpgrade)) {
      setShowModal(true);
    } else {
      onClose();
    }
  }, [isChanged, isUpgradeChanged, isUpgrade, setShowModal, onClose]);
  return (
    <>
      <img
        style={{ zIndex: 1000 }}
        onClick={onCloseModal}
        alt="ico"
        src="/svg/ico-close.svg"
        className="w-6 h-6 absolute right-2 top-2 cursor-pointer"
      />
      <FormProvider {...form}>
        <Header isUpgrade={isUpgrade} />
        <div className="w-735px h-full rounded-lg overflow-auto">
          <CustomerInfoInput b2bUserData={b2bUserData} isUpgrade={isUpgrade} />
          <ContractDocuments attachments={listAttachment} isEdit={isUpgrade} />
        </div>
        <div className="px-4">
          <Submit
            onCloseDetailModal={onCloseDetailModal}
            contractId={contractId}
            isUpgrade={isUpgrade}
            tab={tab}
            onClose={onCloseModal}
            idAdmin={idAdmin}
            onSuccess={onSuccess}
            onCloseUpgrade={onClose}
          />
        </div>
        <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
          <ConfirmModal
            question="Bạn có chắc muốn thoát?"
            contentTop="Bạn đang tạo hợp đồng mới, bạn có"
            contentBottom="chắc chắn muốn thoát?"
            onBack={onClose}
            onContinue={() => setShowModal(false)}
          />
        </MyModalV2>
      </FormProvider>
    </>
  );
}
