import React from 'react';
import { useQuery } from '@apollo/client';
import { READ_MEDIA_URLS } from '@utils/graphql';

type Props = {
  idUrls?: Array,
  onPress?: Function,
};

export default function ReadImageAndVideo(props: Props) {
  const { idUrls, onPress } = props;
  const { data: queryData } = useQuery(READ_MEDIA_URLS, {
    variables: {
      fileId: idUrls,
    },
    skip: !idUrls,
  });
  if (!queryData && (typeof idUrls === 'string' || !idUrls)) {
    return null;
  }
  const imageData = queryData?.readMediaUrls || idUrls;
  if (!imageData) {
    return null;
  }
  const { type } = imageData;
  const src =
    type === 'image'
      ? imageData.originalUrl
      : type === 'youtube'
      ? imageData.youtubeMetadata?.thumbnailUrl
      : type === 'image360'
      ? imageData?.fileId
      : queryData?.readMediaUrls?.originalUrl || '';
  return (
    <div
      className="w-full h-full flex items-center justify-center rounded overflow-hidden cursor-pointer bg-gray-200 border border-gray-200 relative"
      onClick={onPress}
    >
      {src && type === 'video' && (
        <div className="w-40px h-40px absolute z-10">
          <img alt="icon" src="/svg/play-icon.svg" className="w-full h-full" />
        </div>
      )}
      {src && type === 'video' ? (
        <video className="w-full h-87px object-cover" src={src} />
      ) : (
        <img alt="img" src={src} className="w-full h-87px object-cover" />
      )}
    </div>
  );
}
