import gql from 'graphql-tag';
export const GET_LIST_CONTACTS = gql`
  query getListContracts(
    $idAdmin: String!
    $contractCode: String
    $name: String
    $status: [StatusContractEnumType]
    $date: InternalFromToDateStringInputType
    $totalMember: InternalFromToInputType
    $page: Int
    $limit: Int
    $sort: String
    $system: SystemEnumType
  ) @api(name: users) {
    getListContracts(
      idAdmin: $idAdmin
      contractCode: $contractCode
      name: $name
      status: $status
      date: $date
      totalMember: $totalMember
      page: $page
      limit: $limit
      sort: $sort
      system: $system
    ) {
      total
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      items {
        id
        contractCode
        name
        status
        contractDate
        endDate
        dueDate
        totalMember
        createdAt
        system
      }
    }
  }
`;

export const GET_INFO_DETAIL_CONTRACT = gql`
  query getContractDetail($id: String!) @api(name: users) {
    getContractDetail(id: $id) {
      id
      idAdmin
      contractCode
      name
      status
      contractDate
      endDate
      dueDate
      totalMember
      note
      creator
      editor
      createdAt
      updateAt
      totalPrice
      attachments
      system
      discount
      priceMember
      company
    }
  }
`;
