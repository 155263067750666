type Props = {
  isUpgrade?: Boolean,
};
export default function Header({ isUpgrade }: Props) {
  return (
    <div className="mt-5 mr-4 ml-4">
      <div className="text-base font-medium text-blue-main text-center">
        {isUpgrade ? 'Nâng cấp hợp đồng' : 'Thêm hợp đồng'}
      </div>
    </div>
  );
}
