import MyButton from '@components/button';
import Permission from '@gugotech/tncc-users-permissions/lib';
import { useCreatePermissionGroup } from '@utils/hooks/manageOrganization/useCreatePermissionGroup';
import { useUpdatePermissionGroup } from '@utils/hooks/manageOrganization/useUpdatePermissionGroup';
import PropTypes from 'prop-types';
import React from 'react';

Submit.propTypes = {
  isUpdate: PropTypes.bool,
  idGroupPermission: PropTypes.string,
  onBack: PropTypes.func,
  form: PropTypes.object,
  permissions: PropTypes.array,
};
export default function Submit({
  isUpdate,
  onBack,
  idGroupPermission,
  form,
  permissions,
}) {
  const { onSubmit, loading } = useCreatePermissionGroup();
  const { onUpdateSubmit, updateLoading } = useUpdatePermissionGroup();
  const handleSubmitPermissionGroup = async () => {
    const allPermission = Permission.getAllPermissionsBySystem('product');
    const configs = form.watch();
    const selectedPermissions = allPermission?.filter(p =>
        permissions?.includes(p.id)
      ),
      latestPermissions = selectedPermissions?.map(s => {
        if (configs[s.id]) {
          return {
            id: s.id,
            configs: configs[s.id],
          };
        }
        return { id: s.id, configs: s.configs };
      });
    if (isUpdate) {
      await onUpdateSubmit({
        id: idGroupPermission,
        name: configs.name,
        permissions: latestPermissions,
      });
    } else {
      await onSubmit({ name: configs.name, permissions: latestPermissions });
    }
  };

  return (
    <div className="w-full bg-white h-61px flex flex-row items-center justify-end px-4 space-x-2 sticky bottom-0 border-t border-gray-300 z-10">
      <div className="w-147px">
        <MyButton
          disabled={loading}
          onPress={onBack}
          label="Trở về"
          type="gray"
        />
      </div>
      <div className="w-147px">
        <MyButton
          disabled={isUpdate ? updateLoading : loading}
          onPress={handleSubmitPermissionGroup}
          label={isUpdate ? 'Cập nhật' : 'Lưu'}
          type="blue"
        />
      </div>
    </div>
  );
}
