import React from 'react';
import moment from 'moment/moment';
import { useGetUserBasicInfo } from '@utils/hooks/common/useGetUserBasicInfo';

type Props = {
  data?: Object,
};

export default function ContractDetailOtherInfo({ data }: Props) {
  const creator = data?.creator;

  const { userInfo } = useGetUserBasicInfo(creator),
    creatorName = userInfo?.fullName;

  return (
    <div className="flex flex-col space-y-4">
      <div className="rounded-lg border p-4">
        <div className={`text-blue-main text-14 font-medium`}>
          Thông tin người nhập liệu
        </div>
        <div className="flex w-full mt-2.5 py-2 border-b border-gray-300 space-x-6">
          <div className="w-120px text-sm font-medium text-black-main">
            Tên người tạo
          </div>
          <div className="flex flex-row items-center space-x-1">
            <div
              className={`w-6 h-6 rounded-full overflow-hidden border border-blue-main ${
                !userInfo?.avatar?.originalUrl ? 'bg-gray-200' : undefined
              }`}
            >
              {userInfo?.avatar?.originalUrl && (
                <img
                  alt="avt"
                  src={userInfo?.avatar?.originalUrl}
                  className="flex-1"
                />
              )}
            </div>
            <div className="flex-1 text-sm text-black-main">{creatorName}</div>
          </div>
        </div>
        <div className="flex w-full mt-2.5 py-2 border-b border-gray-300 space-x-6">
          <div className="w-120px text-sm font-medium text-black-main">
            Ngày tạo
          </div>
          <div style={{ color: '#80888F' }} className="flex-1 text-sm ">
            {moment(data?.createdAt).format('hh:mm A, DD/MM/YYYY')}
          </div>
        </div>
      </div>
      {data?.note && (
        <div
          style={{ backgroundColor: '#FFF0B1' }}
          className="w-full p-2 rounded-lg space-y-3"
        >
          <div className="text-base text-black-main font-medium">Ghi chú</div>
          <div className="w-full text-13 text-black-main leading-6">
            {data?.note}
          </div>
        </div>
      )}
    </div>
  );
}
