import { useQuery } from '@apollo/client';
import { GET_INTERNAL_REAL_ESTATES_DETAILS } from '@utils/graphql';

export const useGetInternalRealEstateDetail = realEstateId => {
  const { data, loading } = useQuery(GET_INTERNAL_REAL_ESTATES_DETAILS, {
    variables: {
      realEstateId: realEstateId,
    },
    skip: !realEstateId,
  });
  return { data, finalData: data?.getInternalRealEstateDetails || {}, loading };
};
