import Label from '@components/label';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MyInput from '@components/myInput';
import MyTextAreaInput from '@components/textAreaInput';
import SingleCalendar from '@components/singleCalendar';
import MyButton from '@components/button';
import styles from './button.module.scss';
import { numberWithDots } from '@utils/helper';
import RadioButton from '@components/radio';
import { systems } from '../configs';
type Props = {
  b2bUserData?: Object,
  isUpgrade?: Boolean,
};
export default function CustomerInfoInput({ b2bUserData, isUpgrade }: Props) {
  const { control, watch } = useFormContext();
  const [focused, setFocused] = useState(false);
  const totalMember = watch('totalMember');
  const discount = watch('discount');
  const dueDate = watch('dueDate');
  let total = 0;
  if (discount && discount <= 5000000) {
    total = (5000000 - discount) * totalMember;
  } else if (!discount) {
    total = 5000000 * totalMember;
  } else {
    total = 0;
  }
  const wrapperClass = 'w-230px',
    inputClass = 'w-full flex-1';
  return (
    <div className="space-y-6 ml-4 mr-4 mt-7">
      <div className="flex flex-row items-start space-x-2.5">
        <Label
          wrapperClass={wrapperClass}
          label={'Tên hợp đồng'}
          isRequired={true}
        />
        <Controller
          rules={{
            required: 'Vui lòng nhập tên hợp đồng',
          }}
          control={control}
          name="name"
          render={({
            field,
            field: { onChange },
            fieldState: { invalid, error },
          }) => {
            return (
              <MyInput
                {...field}
                invalid={invalid}
                errorMessage={error?.message}
                inputClassName={inputClass}
                placeholder={'Nhập tên hợp đồng'}
                onDelete={() => onChange('')}
              />
            );
          }}
        />
      </div>
      <div className="flex flex-row items-start space-x-2.5">
        <Label
          wrapperClass={wrapperClass}
          label={'Loại hợp đồng'}
          isRequired={true}
        />
        <Controller
          control={control}
          name="system"
          render={({ field: { value, onChange } }) => {
            return (
              <div className={`${inputClass} space-y-2`}>
                <div className="flex flex-row space-x-8">
                  {systems.map((system, index) => {
                    return (
                      <RadioButton
                        disabled={isUpgrade}
                        key={index}
                        title={system.value}
                        isChecked={system.key === value}
                        onPress={() => onChange(system.key)}
                      />
                    );
                  })}
                </div>
              </div>
            );
          }}
        />
      </div>
      {b2bUserData?.name && (
        <div className="flex flex-row items-start space-x-2.5">
          <Label
            wrapperClass={wrapperClass}
            label={b2bUserData?.isPersonal ? 'Tên người dùng' : 'Tên công ty'}
          />
          <div className="flex text-sm flex-row space-x-8">
            {b2bUserData.name}
          </div>
        </div>
      )}
      {!b2bUserData?.isPersonal && b2bUserData?.taxId && (
        <div className="flex flex-row items-start space-x-2.5">
          <Label wrapperClass={wrapperClass} label={'Mã số thuế'} />
          <div className="flex text-sm flex-row space-x-8">
            {b2bUserData?.taxId}
          </div>
        </div>
      )}
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label
          wrapperClass={wrapperClass}
          label="Thời gian kí hợp đồng"
          isRequired={true}
        />
        <Controller
          control={control}
          name="contractDate"
          rules={{
            required: 'Vui lòng chọn thời gian kí hợp đồng',
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <div className={inputClass}>
                <SingleCalendar
                  isMaxDate={false}
                  key={Math.random()}
                  onDelete={() => onChange('')}
                  value={value}
                  setValue={nextValue => onChange(nextValue)}
                  errorMessage={error?.message}
                />
              </div>
            );
          }}
        />
      </div>
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label
          wrapperClass={wrapperClass}
          isRequired={true}
          label="Thời hạn hợp đồng (tháng)"
        />
        <Controller
          rules={{
            required: 'Vui lòng nhập số tháng',
            validate: {
              checkNumber: value => {
                return parseInt(value) < 1 || parseInt(value) > 888
                  ? 'Thời hạn hợp đồng không hợp lệ'
                  : true;
              },
              checkType: value =>
                value?.toString()?.match(/^(0|[1-9][0-9]*)$/)
                  ? true
                  : value?.length > 0
                  ? 'Giá trị không hợp lệ'
                  : true,
            },
          }}
          control={control}
          name={'dueDate'}
          render={({
            field,
            field: { onChange },
            fieldState: { invalid, error },
          }) => {
            return (
              <MyInput
                {...field}
                invalid={invalid}
                errorMessage={error?.message}
                inputClassName={inputClass}
                placeholder="Nhập số tháng"
                onDelete={() => onChange('')}
                maxLength={3}
                hideMaxLength
                type="text"
              />
            );
          }}
        />
      </div>
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label wrapperClass={wrapperClass} label="Ghi chú" />
        <Controller
          control={control}
          name={'note'}
          render={({
            field,
            field: { onChange },
            fieldState: { invalid, error },
          }) => {
            return (
              <MyTextAreaInput
                {...field}
                invalid={invalid}
                errorMessage={error?.message}
                inputClassName={inputClass}
                placeholder="Nhập ghi chú"
                onDelete={() => onChange('')}
                maxlength={888}
              />
            );
          }}
        />
      </div>
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label
          wrapperClass={wrapperClass}
          isRequired={true}
          label="Số lượng nhân viên"
        />
        <Controller
          rules={{
            required: 'Vui lòng nhập số lượng nhân viên',
            validate: {
              checkNumber: value =>
                value?.toString()?.match(/^(0|[1-9][0-9]*)$/)
                  ? true
                  : value?.length > 0
                  ? 'Giá trị không hợp lệ'
                  : true,
            },
          }}
          control={control}
          name={'totalMember'}
          render={({
            field,
            field: { onChange },
            fieldState: { invalid, error },
          }) => {
            return (
              <MyInput
                {...field}
                invalid={invalid}
                errorMessage={error?.message}
                inputClassName={inputClass}
                placeholder="Nhập số nhân viên"
                onDelete={() => onChange('')}
                hideMaxLength
                type="text"
                maxLength={3}
              />
            );
          }}
        />
      </div>
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label
          wrapperClass={wrapperClass}
          label="Giá khuyến mãi/ từng nhân viên"
        />
        <Controller
          rules={{
            validate: {
              checkNumber: value =>
                value?.toString()?.match(/^(0|[1-9][0-9]*)$/)
                  ? true
                  : value?.length > 0
                  ? 'Giá trị không hợp lệ'
                  : true,
            },
          }}
          control={control}
          name={'discount'}
          render={({
            field,
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => {
            return (
              <MyInput
                {...field}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                invalid={invalid}
                errorMessage={error?.message}
                inputClassName={inputClass}
                placeholder="Nhập giá khuyến mãi"
                onDelete={() => onChange('')}
                hideMaxLength
                maxLength={15}
                type={!focused ? undefined : 'text'}
                value={!focused && value ? numberWithDots(value) : value}
              />
            );
          }}
        />
      </div>
      <div
        className="w-full flex flex-row items-start space-x-2.5"
        style={{ marginTop: 10 }}
      >
        <div className="flex flex-row items-center space-x-1 w-230px"></div>
        <div
          dangerouslySetInnerHTML={{
            __html:
              'Giá niêm yết: <span class="text-red-500"> 5.000.000 VNĐ</span>/nhân viên/tháng',
          }}
        />
      </div>
      <div
        className="w-full flex flex-row items-start space-x-2.5"
        style={{ marginTop: 10 }}
      >
        <div className="flex flex-row items-center space-x-1 w-230px"></div>
        <div
          dangerouslySetInnerHTML={{
            __html: `Tổng giá tiền: <span class="text-red-500"> ${numberWithDots(
              dueDate * total
            )} VNĐ</span>`,
          }}
        />
      </div>
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label
          wrapperClass={`${wrapperClass} w-full`}
          label="Tải xuống hợp đồng mẫu"
        />
        <div className="w-full flex-1">
          <a href="https://tncc-b2b-stg.s3.ap-southeast-1.amazonaws.com/20170525105429-mauquyche-final.doc">
            <MyButton
              label="Bấm để tải hợp đồng mẫu"
              type="gray"
              wrapperClass={styles.button}
              labelStyle="flex-none"
              rightComponent={() => {
                return (
                  <div className="ml-2.5">
                    <img src="/svg/download.svg"></img>
                  </div>
                );
              }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}
