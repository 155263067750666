import React, { useState } from 'react';
import ItemMenu from './ItemMenu';
import {
  managePost,
  // managePayment,
  manageUser,
  manageOrganization,
  manageStaff,
  realEstate,
  // manageProject,
} from './configs';
import { useLocation } from 'react-router-dom';
import { View } from 'react-native-web';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

export default function LeftMenu() {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const { permissions } = useGetMe();

  return (
    <View
      style={{
        width: show ? 350 : 80,
        transition: 'width 0.5s',
        borderRightWidth: 1,
        borderColor: '#2459ad',
      }}
      onMouseEnter={() => {
        setShow(true);
      }}
      onMouseLeave={() => setShow(false)}
    >
      <div
        onClick={() => setShow(!show)}
        className="w-full h-50px flex justify-center mt-4 relative"
      >
        {show ? (
          <img
            alt="logo"
            src="/svg/logo.svg"
            className="w-150px h-50px delay-800 duration-500 transition-width transition-height"
          />
        ) : (
          <div className="w-full h-50px flex items-center justify-center">
            <img
              alt="burger"
              src="/svg/burger.svg"
              className="w-6 h-6 cursor-pointer"
            />
          </div>
        )}
        {show && (
          <div
            style={{
              right: 16,
              top: 0,
            }}
            onClick={() => setShow(!show)}
            className="flex items-center justify-center w-7 h-7 absolute cursor-pointer hover:bg-gray-200 duration-300 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className={`w-6 h-6 text-gray-400 hover:text-white`}
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            {/*<img alt="close" src="/svg/ico-close.svg" className="w-6 h-6" />*/}
          </div>
        )}
      </div>
      <div className="flex flex-col h-full w-full px-4 mt-10 space-y-8">
        <ItemMenu
          src="/svg/dasboard.svg"
          title="thống kê - báo cáo"
          showArrow={false}
          isHover={show}
          onTitleClick={() => setShow(true)}
        />
        {permissions?.some(p =>
          [
            PermissionIds.SaleAndRegionManagement,
            PermissionIds.ViewListB2BCustomers,
          ].includes(p.id)
        ) && (
          <ItemMenu
            src={
              location.pathname.includes('manageUser')
                ? '/svg/people-active.svg'
                : '/svg/people.svg'
            }
            configs={manageUser}
            isActive={location.pathname.includes('manageUser')}
            isHover={show}
            onTitleClick={() => setShow(true)}
          />
        )}
        <ItemMenu
          src="/svg/organization.svg"
          configs={manageOrganization}
          isHover={show}
          onTitleClick={() => setShow(true)}
        />
        {/* <ItemMenu
          src="/svg/permission.svg"
          title="Phân quyền"
          isHover={show}
          onTitleClick={() => setShow(true)}
        /> */}
        {permissions?.some(p =>
          [
            PermissionIds.ManageEmployee,
            PermissionIds.ViewEmployeeList,
            PermissionIds.AddEmployee,
          ].includes(p.id)
        ) && (
          <ItemMenu
            src="/svg/group.svg"
            title="Nhân viên"
            configs={manageStaff}
            isHover={show}
            onTitleClick={() => setShow(true)}
          />
        )}
        {permissions?.some(p =>
          [
            PermissionIds.SaleAndRegionManagement,
            PermissionIds.ViewB2CRealEstateList,
            PermissionIds.ViewB2CApprovedRealEstateList,
            PermissionIds.ViewB2CRejectedRealEstateList,
          ].includes(p.id)
        ) && (
          <ItemMenu
            src={
              location.pathname.includes('manageRealEstate')
                ? '/svg/managePostActive.svg'
                : '/svg/managePost.svg'
            }
            configs={managePost}
            isActive={location.pathname.includes('manageRealEstate')}
            isHover={show}
            onTitleClick={() => setShow(true)}
          />
        )}
        {/* <ItemMenu
          src="/svg/managePayment.svg"
          title="quản lý thanh toán"
          configs={managePayment}
          isHover={show}
          onTitleClick={() => setShow(true)}
        /> */}
        {permissions?.some(p =>
          [
            PermissionIds.ManageProducts,
            PermissionIds.ViewProductList,
            PermissionIds.ViewProductGroup,
          ].includes(p.id)
        ) && (
          <ItemMenu
            src="/svg/building.svg"
            configs={realEstate}
            isHover={show}
            onTitleClick={() => setShow(true)}
          />
        )}
        {/* <ItemMenu
          title="Bản đồ quy hoạch"
          src="/svg/map.svg"
          isHover={show}
          onTitleClick={() => setShow(true)}
        /> */}
        {/* <ItemMenu
          src="/svg/manageProject-icon.svg"
          configs={manageProject}
          isHover={show}
          onTitleClick={() => setShow(true)}
        /> */}
        {/* <ItemMenu
          src="/svg/manageBuilding.svg"
          title="quản lý vận hành tòa nhà"
          isHover={show}
          onTitleClick={() => setShow(true)}
        />
        <ItemMenu
          src="/svg/setting.svg"
          title="Cài đặt"
          isHover={show}
          onTitleClick={() => setShow(true)}
        /> */}
      </div>
    </View>
  );
}
