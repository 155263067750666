import styles from './contractDetailModal.module.scss';
import SwitchInner from '@components/switchInner';
import React, { useMemo, useState } from 'react';
import ContractInfoDetail from './components/contractInfoDetail';
import MyButton from '@components/button';
import RenderRightButton from './components/renderRightButton';
import { useGetContractDetail } from '@utils/hooks/manageContract/useGetContractDetail';
import { useDeepCompareEffect } from 'react-use';
import { getMedias } from '@utils/helper';

const switchTabConfigs = () => {
  return [
    {
      key: '0',
      value: 'Chi tiết hợp đồng',
    },
  ];
};

type Props = {
  contractId?: string,
  onClose?: Function,
  isLocked?: boolean,
  b2bUserData?: Object,
  onSuccess?: Function,
};
export default function ContractDetailModal({
  contractId,
  onClose,
  isLocked,
  b2bUserData,
  onSuccess,
}: Props) {
  const [currentTab, setCurrentTab] = useState('0');
  const [listAttachment, setListAttachment] = useState([]);
  const tabConfigs = switchTabConfigs();
  const { contractDetail } = useGetContractDetail({ id: contractId });
  const renderContent = useMemo(() => {
    switch (currentTab) {
      default:
      case '0':
        return (
          <ContractInfoDetail
            contractDetail={contractDetail}
            listAttachment={listAttachment}
            contractId={contractId}
            b2bUserData={b2bUserData}
          />
        );
    }
  }, [currentTab, contractId, contractDetail, listAttachment, b2bUserData]);
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const data = await getMedias(contractDetail?.attachments || []);
        setListAttachment(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [contractDetail?.attachments]);
  return (
    <div className={styles.modalContainer}>
      <div className="sticky top-0 bg-white">
        <SwitchInner
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          configs={tabConfigs}
        />
      </div>
      <div className="w-full h-full">
        <div className="flex space-x-4 py-5 px-2">
          {renderContent}
          <RenderRightButton
            onCloseDetailModal={onClose}
            onSuccess={onSuccess}
            isLocked={isLocked}
            contractId={contractId}
            contractDetail={contractDetail}
            listAttachment={listAttachment}
            b2bUserData={b2bUserData}
          />
        </div>
        <div className="w-full h-60px flex justify-end items-center border-t border-gray-300 sticky bottom-0 bg-white">
          <div className="w-160px">
            <MyButton onPress={onClose} type="gray" label="Trở về" />
          </div>
        </div>
      </div>
    </div>
  );
}
