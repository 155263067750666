import { View } from 'react-native-web';
import Switch from '@components/switch';
import RenderTopTable from './components/renderTopTable';
import React, { useState } from 'react';
import { ManageProjectContext } from './context';
import RenderTableProject from './components/renderTableProject';
import { useGetCompanyInfo } from '@utils/hooks/manageUserB2B/useCompanyInfo';
import { useQuery } from '@apollo/client';
import { GET_ME } from '@utils/graphql';
import { useGlobalContext } from '../../../../context';
import { useGetListRealEstateProjects } from '@utils/hooks/manageProject/useGetListRealEstateProjects';
import { useList } from 'react-use';
import { unionBy } from 'lodash';
import { useStateFilterProject } from '@utils/hooks/manageProject/useStateFilterProject';
import ProjectDetail from '../projectDetail';

export default function Project() {
  const { isLogin } = useGlobalContext();
  const [tab, setTab] = useState('/project/company');
  const [previousId, setPreviousId] = useState('');
  const [listSelected, setListSelected] = useList([]);
  const { data: myInfo } = useQuery(GET_ME, {
    skip: !isLogin,
  });
  const idCompany = myInfo?.b2bGetMe?.payload?.company;
  const { data: b2bUserData } = useGetCompanyInfo(idCompany);
  const {
    state: stateFilter,
    setStateAt: setStateFilterAt,
    setState,
  } = useStateFilterProject();
  const {
    projectRealEstates,
    total,
    loading,
    isLoadingMore,
    hasNextPage,
    onLoadMore,
  } = useGetListRealEstateProjects({
    state: stateFilter,
  });
  return (
    <ManageProjectContext.Provider
      value={React.useMemo(
        () => ({
          listSelected: unionBy(listSelected, e => e),
          setListSelected,
          tab,
          setTab,
          previousId,
          setPreviousId,
          stateFilter,
          setStateFilterAt,
          setState,
        }),
        [
          tab,
          setTab,
          previousId,
          setPreviousId,
          listSelected,
          setListSelected,
          stateFilter,
          setStateFilterAt,
          setState,
        ]
      )}
    >
      <View style={{ flex: 1 }}>
        <View
          style={{
            marginTop: 4,
            backgroundColor: 'white',
            paddingHorizontal: 16,
            paddingTop: 20,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          <Switch
            value={tab}
            setValue={value => {
              setTab(value);
            }}
            configs={[
              {
                title: `Dự án của chủ đầu tư ${b2bUserData?.name}`,
                pathname: '/project/company',
              },
            ]}
          />
        </View>
        <View style={{ width: '100%', height: '100%' }}>
          <View
            style={{
              width: '100%',
              backgroundColor: 'white',
              paddingBottom: 12,
            }}
          >
            <div className="px-4 pt-2">
              <RenderTopTable
                total={total}
                currentTotal={projectRealEstates?.length}
                title={`Dự án của chủ đầu tư ${b2bUserData?.name}`}
              />
            </div>
            <div className="w-full px-4">
              <RenderTableProject
                data={projectRealEstates}
                loading={loading}
                onLoadMore={onLoadMore}
                canLoadMore={hasNextPage}
                isLoadingMore={isLoadingMore}
              />
            </div>
          </View>
        </View>
      </View>
      {listSelected?.length === 1 ? (
        <ProjectDetail />
      ) : (
        <div className="p-4 text-black-main">
          Vui lòng chọn 1 dự án để xem chi tiết
        </div>
      )}
    </ManageProjectContext.Provider>
  );
}
