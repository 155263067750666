import gql from 'graphql-tag';

export const SIGN_IN = gql`
  mutation b2bSignIn(
    $username: String!
    $password: String!
    $savedLogin: Boolean
  ) @api(name: users) {
    b2bSignIn(
      username: $username
      password: $password
      savedLogin: $savedLogin
    ) {
      success
      msg
      payload {
        accessToken
        memberId
        system
        id
      }
    }
  }
`;
export const LOGOUT = gql`
  mutation b2bLogOut @api(name: users) {
    b2bLogOut {
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation b2bChangPassword(
    $oldPassword: String!
    $newPassword: String!
    $confirmNewPassword: String!
  ) @api(name: users) {
    b2bChangePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
    ) {
      success
      msg
      payload
    }
  }
`;
