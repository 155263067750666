import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import MyToast from '@components/MyToast';
import { UPDATE_B2B_ADMIN_PERMISSIONS } from '@utils/graphql/mutations/b2bAdminUsers';
export const useUpdateAdminPermissions = onClose => {
  const [updateAdminPermissions, { loading, error, client }] = useMutation(
    UPDATE_B2B_ADMIN_PERMISSIONS
  );
  const toast = useToast();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (permissions, system, adminId) => {
      try {
        const newPermissions = permissions?.map(p => {
          return { id: p.id, configs: p?.configs };
        });
        await updateAdminPermissions({
          variables: {
            adminId,
            system,
            newPermissions,
          },
        });
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              wrapperStyle="w-394px shadow-md"
              type="success"
              message="Phân quyền cho admin thành công!"
              closeButtonPos="center"
            />
          ),
        });
        await resetQueries(['b2bGetUserDetailsForBO'], client.cache);
        await navigate('/manageUser/b2b');
        onClose();
      } catch (e) {
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              type="error"
              message={e.message}
              closeButtonPos="center"
            />
          ),
        });
      }
    },
    [updateAdminPermissions, client, navigate]
  );
  return { onSubmit, loading, error };
};
