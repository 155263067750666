import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { READ_MEDIA_URLS } from '@utils/graphql';
import FileViewer from 'react-file-viewer';
import Viewer from 'react-viewer';

type Props = {
  fileId?: string,
  onClose?: Function,
};

export default function ViewFileModal({ fileId, onClose }: Props) {
  const { data } = useQuery(READ_MEDIA_URLS, {
    variables: {
      fileId,
    },
    skip: !fileId,
  });

  const { originalUrl, type } = data?.readMediaUrls || {};

  const renderImage = useMemo(() => {
    return (
      <Viewer
        visible={true}
        onClose={onClose}
        onMaskClick={onClose}
        images={[{ src: originalUrl }]}
        zoomSpeed={0.3}
        scalable={false}
        showTotal={false}
        noImgDetails={true}
        noNavbar={true}
      />
    );
  }, [originalUrl]);

  const renderFileViewer = useMemo(() => {
    return (
      <FileViewer fileType={getFileViewerType(type)} filePath={originalUrl} />
    );
  }, [originalUrl, type]);
  if (!data) {
    return null;
  }
  return type === 'image' ? (
    renderImage
  ) : (
    <div className="w-700px bg-transparent rounded-lg h-600px items-center justify-center overflow-hidden pl-4 pr-4">
      {renderFileViewer}
    </div>
  );
}

export function getFileViewerType(type) {
  switch (type) {
    case 'doc':
      return 'docx';
    case 'sheet':
      return 'xlsx';
    case 'pdf':
      return 'pdf';
  }
}
