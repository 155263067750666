import MyButton from '@components/button';
import RecursiveTreeView from '@components/treeView';
import { Permission } from '@gugotech/tncc-users-permissions';
import React, { useCallback, useEffect, useState } from 'react';
import CheckboxUsingTable from '@components/checkboxUsingTable';
import { useUpdateAdminPermissions } from '@utils/hooks/b2bUser/useUpdateAdminPermissions';

type Props = {
  onClose?: Function,
  onConfirm?: Function,
  header?: string,
  question?: string,
  content?: string,
  loading?: boolean,
  system?: string,
  adminId?: string,
  adminData?: Object,
};

export default function AssignAdminPermissions({
  onClose,
  system,
  adminId,
  adminData,
}: Props) {
  const [checkAll, setCheckAll] = useState(false);
  const [partialCheck, setPartialCheck] = useState(false);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (adminData[`${system}Permissions`]?.length) {
      setSelected(adminData[`${system}Permissions`]?.map(p => p.id));
    }
  }, [setSelected, adminData, system]);
  const { onSubmit, loading } = useUpdateAdminPermissions(onClose);
  const onConfirm = useCallback(() => {
    const selectedPermissions = Permission.getAllPermissionsBySystem(
      system
    )?.filter(p => selected?.includes(p.id));
    onSubmit(selectedPermissions, system, adminId);
  }, [selected, system, adminId]);
  return (
    <div className="w-450px max-h-full bg-white rounded-lg flex flex-col p-4 overflow-hidden overflow-y-auto">
      <div className="text-16 font-medium text-blue-main">
        Cấp quyền Hợp đồng {system === 'product' ? 'BĐS riêng lẻ' : 'Dự án'}
      </div>
      <div className="flex flex-row justify-start space-x-2 mt-2">
        <CheckboxUsingTable
          isChecked={checkAll}
          onPress={() => setCheckAll(!checkAll)}
          isPartialCheck={partialCheck}
        />
        <span>Chọn tất cả </span>
      </div>
      <div className="w-full mt-3 text-sm">
        <RecursiveTreeView
          system={system}
          selected={selected}
          setSelected={setSelected}
          setPartialCheck={setPartialCheck}
          checkAll={checkAll}
          permissions={Permission.getPermissionTree(system)}
        />
      </div>
      <div className="flex w-full justify-end space-x-2 mt-6">
        <div className="w-140px">
          <MyButton label="Trở về" type="gray" onPress={onClose} />
        </div>
        <div className="w-140px">
          <MyButton
            disabled={loading}
            type="blue"
            label={loading ? 'Đang xử lý' : 'Xác nhận'}
            wrapperClass="w-140px"
            onPress={onConfirm}
          />
        </div>
      </div>
    </div>
  );
}
