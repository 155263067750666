import { useQuery } from '@apollo/client';
import { GET_INTERNAL_REAL_ESTATES_HISTORIES } from '@utils/graphql';
import { useMemo, useState } from 'react';
import { useLoadByPagination } from '@utils/hooks/common/useLoadByPagination';
import { cleanObj } from '@utils/helper';
import { useLoadMore } from '../common/useLoadMore';

export const useGetProductHistory = ({ realEstateId, params = {} } = {}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const variables = useMemo(() => {
    return {
      realEstateId,
      ...cleanObj(params),
    };
  }, [realEstateId, params]);
  const { data: rawData, fetchMore } = useQuery(
    GET_INTERNAL_REAL_ESTATES_HISTORIES,
    {
      variables,
      skip: !realEstateId,
    }
  );
  const data = rawData?.getRealEstateHistories.items || [];
  const currentPage = rawData?.getRealEstateHistories?.page || 1;
  const totalItem = rawData?.getRealEstateHistories?.total;
  const totalPage = rawData?.getRealEstateHistories?.totalPages;
  const rowsPerPage = rawData?.getRealEstateHistories?.limit;
  const currentCountFrom = rawData?.getRealEstateHistories?.pagingCounter;
  const currentCountTo = currentCountFrom + data.length - 1;
  const hasPrevPage = rawData?.getRealEstateHistories?.hasPrevPage;
  const hasNextPage = rawData?.getRealEstateHistories?.hasNextPage;
  const onLoadByPagination = currentPage =>
    useLoadByPagination({
      loading,
      setLoading,
      fetchMore,
      currentPage,
    });
  const onLoadMore = useLoadMore({
    variables,
    isLoadingMore,
    setIsLoadingMore,
    hasNextPage,
    currentPage,
    fetchMore,
  });
  return {
    data,
    loading,
    onLoadByPagination,
    onLoadMore,
    currentPage,
    totalItem,
    totalPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  };
};
