import gql from 'graphql-tag';

export const CREATE_B2B_CONTRACT = gql`
  mutation b2bCreateContract($data: ContractInputType) @api(name: users) {
    b2bCreateContract(data: $data) {
      success
      msg
      payload
    }
  }
`;

export const UPGRADE_B2B_CONTRACT = gql`
  mutation b2bUpgradeContract(
    $data: UpgradeContractInputType
    $contractId: String!
  ) @api(name: users) {
    b2bUpgradeContract(data: $data, contractId: $contractId) {
      success
      msg
      payload
    }
  }
`;
