import { useQuery } from '@apollo/client';
import { READ_MEDIA_URLS } from '@utils/graphql';
import React from 'react';

type Props = {
  avatarId?: string,
  wrapperStyle?: string,
};

export default function RenderAvatarComponent({
  avatarId,
  wrapperStyle,
}: Props) {
  const { data } = useQuery(READ_MEDIA_URLS, {
    variables: {
      fileId: avatarId,
    },
    skip: !avatarId,
  });
  const avatar = data?.readMediaUrls.originalUrl || avatarId?.originalUrl;
  return (
    <div>
      <div
        className={`${wrapperStyle} flex rounded-rd50 border border-gray-300 bg-no-repeat bg-cover bg-center`}
        style={{
          backgroundImage: `url('${avatar || '/svg/male.svg'}')`,
        }}
      />
    </div>
  );
}
