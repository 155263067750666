import { useQuery } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import React, { useState } from 'react';
import CropAvatar from '@components/changeAvatar/cropAvatar';
import { READ_MEDIA_URLS } from '@utils/graphql';
import styles from './changeAvatar.module.scss';
import MyModalV2 from '@components/modalV2/myModalV2';
type Props = {
  avatarUrl?: string,
  avatarId?: string,
  wrapperClass?: string,
  setAvatar?: Function,
  disabled?: boolean,
  setShowErrModal?: Function,
};

export default function ChangeAvatar(props: Props) {
  const {
      avatarUrl,
      avatarId,
      wrapperClass,
      setAvatar = () => {},
      disabled,
      setShowErrModal,
    } = props,
    [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState('');

  const { getInputProps, getRootProps } = useDropzone({
    accept: ['image/*'],
    onDrop: async files => {
      if (files[0]?.size / 1048576 > 1) {
        setShowErrModal(true);
      } else if (files[0]) {
        setFile(files[0]);
        setShowModal(true);
      }
    },
  });
  const { data } = useQuery(READ_MEDIA_URLS, {
    variables: {
      fileId: avatarId,
    },
    skip: avatarUrl || !avatarId,
  });

  const avatar = avatarUrl || data?.readMediaUrls.originalUrl || avatarId;

  const nonAvatar = !avatar?.length;

  const isDisabled = showModal || disabled;

  return (
    <div
      {...getRootProps()}
      className={`${styles.changeAvatarContainer} ${
        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } ${wrapperClass} ${nonAvatar && 'border border-gray-300'}`}
      style={{
        backgroundImage: `url("${avatar}")`,
      }}
    >
      {nonAvatar && (
        <img
          src="/svg/male.svg"
          className="w-full h-full rounded-full"
          alt="avt"
        />
      )}

      <input
        {...getInputProps()}
        style={{ display: 'none' }}
        type="file"
        multiple={false}
        disabled={isDisabled}
      />
      <div className={styles.camera}>
        <img alt="camrea" src="/svg/camera.svg" className="w-6 h-6  " />
      </div>
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <CropAvatar
          close={() => setShowModal(false)}
          configs={{
            previewUrl: file ? URL.createObjectURL(file) : '',
            file,
            onChangeImage: setAvatar,
          }}
        />
      </MyModalV2>
    </div>
  );
}
