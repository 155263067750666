import React from 'react';
import { Image } from 'react-native-web';

export default function LoadingScreen() {
  return (
    <div className="w-full h-screen">
      <Image
        style={{ width: '100%', height: '100%' }}
        source="/images/loadingBanner.png"
      />
    </div>
  );
}
