import React from 'react';

type ItemProps = {
  title?: string,
  value?: string,
  valueClassName?: string,
  hideField?: boolean,
};
export default function ItemRowContractDetail({
  title,
  value = '',
  valueClassName,
  hideField,
}: ItemProps) {
  if (hideField) {
    return null;
  }
  return (
    <div className="flex-1 flex w-full py-2 border-b border-gray-300 space-x-10">
      <div className="text-sm font-medium text-black-main w-160px">{title}</div>
      <div
        style={{ color: value ? '#22313E' : '#80888F' }}
        dangerouslySetInnerHTML={{ __html: value ? value : 'Không có' }}
        className={`text-sm ${valueClassName} ${!value ? 'italic' : undefined}`}
      />
    </div>
  );
}
