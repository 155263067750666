import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { SIGN_IN } from '../../graphql';
import { useGlobalContext } from '../../../context';
import { useNavigate } from 'react-router-dom';

const useLoginSuccess = () => {
  const apolloClient = useApolloClient();
  const { setIsLogin } = useGlobalContext();
  return useCallback(
    (callback = () => {}) => {
      setIsLogin(true);
      apolloClient.resetStore();
      callback();
    },
    [apolloClient]
  );
};

export function useLogin() {
  const [login, { loading, error }] = useMutation(SIGN_IN),
    loginSuccess = useLoginSuccess();
  const navigate = useNavigate();
  const doLogin = useCallback(
    async variables => {
      try {
        const { data } = await login({
          variables: {
            username: variables?.username,
            password: variables?.password,
            savedLogin: variables?.isSavedToken,
          },
        });
        if (data?.b2bSignIn?.payload?.accessToken) {
          await loginSuccess(() => {
            localStorage?.setItem(
              'silverBullet',
              data?.b2bSignIn?.payload?.accessToken
            );
            localStorage?.setItem('id', data?.b2bSignIn?.payload?.id);
            localStorage?.setItem('system', data?.b2bSignIn?.payload?.system);
          });
          await navigate('/manageRealEstate/needApproval');
        }
      } catch (e) {
        console.log(e);
      }
    },
    [navigate, login]
  );
  return { doLogin, loading, error };
}
