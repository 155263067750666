import gql from 'graphql-tag';

export const CREATE_B2B_ADMIN_ACCOUNT = gql`
  mutation b2bCreateNewAdminAndCompany(
    $companyDistrict: String!
    $companyHouseNumber: String!
    $companyName: String!
    $companyProvince: String!
    $companyStreet: String!
    $companyWard: String!
    $district: String!
    $province: String!
    $street: String!
    $ward: String!
    $email: String!
    $fullName: String!
    $isPersonal: Boolean!
    $password: String!
    $phone: String!
    $taxIdOrIdNumber: String!
    $username: String!
    $logo: String
    $avatar: String
    $secondPhone: String
    $attachments: [String]
    $dateOfBirth: Date
    $gender: GenderEnumType
    $idNumber: String
    $idImages: [String]
    $houseNumber: String
  ) @api(name: users) {
    b2bCreateNewAdminAndCompany(
      companyDistrict: $companyDistrict
      companyHouseNumber: $companyHouseNumber
      companyName: $companyName
      companyProvince: $companyProvince
      companyStreet: $companyStreet
      companyWard: $companyWard
      district: $district
      province: $province
      street: $street
      ward: $ward
      email: $email
      fullName: $fullName
      isPersonal: $isPersonal
      password: $password
      phone: $phone
      taxIdOrIdNumber: $taxIdOrIdNumber
      username: $username
      logo: $logo
      avatar: $avatar
      secondPhone: $secondPhone
      attachments: $attachments
      dateOfBirth: $dateOfBirth
      gender: $gender
      idNumber: $idNumber
      idImages: $idImages
      houseNumber: $houseNumber
    ) {
      success
      msg
      payload
    }
  }
`;

export const UPDATE_B2B_ADMIN_ACCOUNT = gql`
  mutation b2bUpdateAdmin(
    $id: String!
    $companyDistrict: String
    $companyHouseNumber: String
    $companyName: String
    $companyProvince: String
    $companyStreet: String
    $companyWard: String
    $district: String
    $province: String
    $street: String
    $ward: String
    $email: String
    $fullName: String
    $isPersonal: Boolean
    $password: String
    $phone: String
    $taxIdOrIdNumber: String
    $username: String
    $logo: String
    $avatar: String
    $secondPhone: String
    $attachments: [String]
    $dateOfBirth: Date
    $gender: GenderEnumType
    $idNumber: String
    $idImages: [String]
    $houseNumber: String
  ) @api(name: users) {
    b2bUpdateAdmin(
      id: $id
      companyDistrict: $companyDistrict
      companyHouseNumber: $companyHouseNumber
      companyName: $companyName
      companyProvince: $companyProvince
      companyStreet: $companyStreet
      companyWard: $companyWard
      district: $district
      province: $province
      street: $street
      ward: $ward
      email: $email
      fullName: $fullName
      isPersonal: $isPersonal
      password: $password
      phone: $phone
      taxIdOrIdNumber: $taxIdOrIdNumber
      username: $username
      logo: $logo
      avatar: $avatar
      secondPhone: $secondPhone
      attachments: $attachments
      dateOfBirth: $dateOfBirth
      gender: $gender
      idNumber: $idNumber
      idImages: $idImages
      houseNumber: $houseNumber
    ) {
      success
      msg
      payload
    }
  }
`;

export const UPDATE_B2B_ADMIN_PERMISSIONS = gql`
  mutation b2bUpdatePermissionsAdmin(
    $adminId: String
    $newPermissions: [PermissionInputType]
    $updatePermissions: [PermissionInputType]
    $deletePermissionIds: [String]
    $system: SystemEnumType!
  ) @api(name: users) {
    b2bUpdatePermissionsAdmin(
      adminId: $adminId
      newPermissions: $newPermissions
      updatePermissions: $updatePermissions
      deletePermissionIds: $deletePermissionIds
      system: $system
    ) {
      success
      msg
      payload
    }
  }
`;
