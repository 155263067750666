import React, { useLayoutEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import CollapsibleV2 from '../collapsibleV2';
import { useToggle } from 'react-use';
import { useLocation, useNavigate } from 'react-router';
import styles from './leftMenu.module.scss';
type Props = {
  src?: string,
  wrapperClass?: string,
  title?: string,
  isHover?: boolean,
  configs?: Object,
  onTitleClick?: Function,
  isActive?: boolean,
};

export default function ItemMenu(props: Props) {
  const {
    src,
    wrapperClass,
    title,
    isHover,
    configs = {},
    onTitleClick,
    isActive,
  } = props;

  const [expand, setExpand] = useToggle();

  const style = useSpring({
    display: isHover ? 'flex' : 'none',
    config: {
      duration: 250,
    },
  });
  useLayoutEffect(() => {
    if (!isHover) {
      setExpand(false);
    }
  }, [isHover]);
  return (
    <div className={`flex flex-col w-full pb-5 border-b ${wrapperClass}`}>
      <div
        onClick={() => {
          setExpand();
          onTitleClick && onTitleClick();
        }}
        className="flex w-full space-x-1.5 cursor-pointer"
      >
        <img alt="svg" src={src} className="w-6 h-6" />
        <div className="flex flex-row w-full items-center justify-between truncate whitespace-nowrap">
          <animated.div style={style}>
            <div
              className={`text-14 font-medium ${
                isActive ? ' text-blue-main' : 'text-gray-400'
              }`}
              style={{
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
              }}
            >
              {configs?.title || title}
            </div>
          </animated.div>
          {configs?.items?.length && (
            <img
              alt="arr"
              src={`${
                isActive || expand ? '/svg/arrowActive.svg' : '/svg/arrow.svg'
              }`}
              className={`${styles.arrowIcon} transform duration-300 ${
                !isHover ? '-rotate-90' : expand ? 'rotate-180' : undefined
              }`}
            />
          )}
        </div>
      </div>
      <CollapsibleV2 expand={expand}>
        <div
          className={`${
            isHover ? 'flex' : 'hidden'
          } py-2 mt-2 flex-col w-full space-y-2`}
        >
          {configs?.items?.map((item, index) => {
            return (
              <SubMenu isHover={isHover} key={`subMenu-${index}`} {...item} />
            );
          })}
        </div>
      </CollapsibleV2>
    </div>
  );
}

type SubMenuProps = {
  title?: string,
  items?: [{ title?: string, pathname?: string }],
  isHover?: boolean,
};

function SubMenu(props: SubMenuProps) {
  const { title, items, isHover } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [expand, setExpand] = useToggle();
  useLayoutEffect(() => {
    if (!isHover) {
      setExpand(false);
    }
  }, [isHover]);
  return (
    <div className="flex flex-col w-full cursor-pointer space-y-1">
      {title && (
        <div
          style={{ paddingLeft: 32 }}
          className="flex w-full items-center justify-between"
          onClick={setExpand}
        >
          <div
            style={{ color: expand ? '#2459ad' : '#80888F' }}
            className="text-sm font-medium"
          >
            {title}
          </div>
          <img
            alt="arr"
            src={`${expand ? '/svg/arrowActive.svg' : '/svg/arrow.svg'}`}
            className={`${styles.arrowIcon} transform ${
              expand ? 'rotate-180' : undefined
            }`}
          />
        </div>
      )}
      <CollapsibleV2 expand={!title ? true : expand}>
        <div
          className={`flex flex-col space-y-2.5 ${title ? 'py-1' : undefined}`}
        >
          {items?.map((item, index) => {
            const isActiveTitle = item?.pathname === location.pathname;
            return (
              <div
                key={`sub-${index}`}
                onClick={() => navigate(item.pathname)}
                style={{
                  color: isActiveTitle ? '#2459ad' : '#80888F',
                  backgroundColor: isActiveTitle ? '#EDF4FF' : undefined,
                  paddingLeft: 56,
                }}
                className={`${styles.itemSelect}`}
              >
                {item.title}
              </div>
            );
          })}
        </div>
      </CollapsibleV2>
    </div>
  );
}
