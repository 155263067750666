import React from 'react';
import { View } from 'react-native-web';
import LeftMenu from '@components/leftMenu/leftMenu';
import Header from '@components/header';
import LoadingScreen from '../loadingScreen';

export default function Dashboard() {
  return (
    <div
      style={{
        flex: 1,
        // height: '100vh',
        backgroundColor: '#f4f4f4',
      }}
    >
      <div
        style={{ zIndex: 1000 }}
        className="fixed top-0 h-screen overflow-y-auto bg-white"
      >
        <LeftMenu />
      </div>
      <View
        style={{
          flex: 1,
          marginLeft: 80,
          backgroundColor: '#f4f4f4',
        }}
      >
        <Header />
        <LoadingScreen />
      </View>
    </div>
  );
}
