import { useMutation } from '@apollo/client';
import { LOGOUT } from '../../graphql';
import { useGlobalContext } from '../../../context';
import { useNavigate } from 'react-router-dom';
export default function useLogOut() {
  const { setIsLogin } = useGlobalContext();
  const navigate = useNavigate();
  const [logout] = useMutation(LOGOUT, {
    onCompleted: async () => {
      setIsLogin(false);
      localStorage.clear();
      navigate('/auth/login');
    },
  });
  return { logout };
}
