import gql from 'graphql-tag';
import { FILE_ALL_FIELDS } from '@utils/graphql/fragments';
import { MEDIA_UNION } from '@utils/graphql';
export const GET_REAL_ESTATE_FEES = gql`
  query GetRealEstateFees(
    $startDate: Date!
    $endDate: Date!
    $postType: Int!
    $actionType: RealEstateActionType
  ) @api(name: realEstate) {
    getRealEstateFees(
      startDate: $startDate
      endDate: $endDate
      postType: $postType
      actionType: $actionType
    ) {
      basicFees
      vat
      totalFees
      totalDays
    }
  }
`;
export const GET_REAL_ESTATE_DETAILS = gql`
  query getRealEstateDetails($realEstateId: ObjectID) @api(name: realEstate) {
    getRealEstateDetails(realEstateId: $realEstateId) {
      id
      latestRenewInfo {
        startTime
        endTime
        postType
        basicAmount
        vat
      }
      realEstateType
      demandEstate
      realEstateId
      type
      fullAddress
      isForSell
      isIncognito
      province {
        id
        name
      }
      district {
        id
        name
      }
      ward {
        id
        name
      }
      street {
        id
        name
      }
      constructionArea
      mediaUrlsIds
      houseNumber
      hasAlley
      alleyTurnCount
      timeForRent
      timeForRentUnit
      frontHomeAlleyWidth
      isNewConstruction
      width
      length
      rear
      area
      floorCount
      onFloor
      block
      bedRoomArea
      villaView
      price
      tnccPrice
      priceUnit
      bedroomCount
      roomCount
      hasSeaView
      motelName
      hotelStar
      shophouseField
      investor
      buildingType
      roomArea
      roomNumber
      toiletCount
      direction
      project
      postTitle
      postContent
      privateNote
      legals
      legalMediaUrls {
        ...FileAllFields
      }
      ownerName
      contactName
      contactPhone1
      contactPhone2
      contactPhones
      contactEmail
      postType
      mediaUrls {
        ...FileAllFields
      }
      startShowTime
      endShowTime
      tradingStatus
      creator {
        id
        type
        fullName
        avatar {
          ... on FileWithFullUrls {
            originalUrl
            fileId
          }
        }
      }
      map {
        type
        coordinates
      }
      fixedUtilities
      utilities
      localUtilities
      equipmentsAndServices
      warehouseType
      warehouseRoomCount
      hasMezzanine
      createdAt
      updatedAt
      isSaved
      isNeedToRenew
      latestRenewInfo {
        id
        startTime
        endTime
        postType
        basicAmount
        vat
      }
      isAuthorizedRe
      isSaleOffRE
      numberOfPostingDay
      company
      originalProductId
      service
      isDeleted
      hasImage
      hasVideo
      has360Image
      ownerName
      ownerEmail
      ownerPhone
      career
      priceOfOwner
      priceUnitOfOwner
      commission
      bathroomCount
      floorId
      projectId
      companyNote
      industryTrade
      remainingActivePost
      approvalStatus
      postStatus
      approvalReason
      approvedAt
      approvedBy {
        id
        fullName
        avatar {
          ... on FileWithFullUrls {
            originalUrl
            fileId
          }
        }
      }
      project
    }
  }
  ${FILE_ALL_FIELDS}
`;
export const GET_NEED_REAL_ESTATE_DETAILS = gql`
  query getNeededRealEstateDetails($realEstateId: ObjectID)
  @api(name: realEstate) {
    getNeededRealEstateDetails(realEstateId: $realEstateId) {
      id
      demandEstate
      type
      province {
        id
        name
      }
      district {
        id
        name
      }
      fullAddress
      isNeedToBuy
      isIncognito
      isNewly
      ward {
        id
        name
      }
      creator {
        id
        fullName
        avatar {
          ... on FileWithFullUrls {
            originalUrl
          }
        }
      }
      street {
        id
        name
      }
      hasAlley
      timeForRent {
        from
        to
      }
      width {
        from
        to
      }
      constructionArea {
        from
        to
      }
      length {
        from
        to
      }
      rear {
        from
        to
      }
      area {
        from
        to
      }
      price {
        from
        to
      }
      clearanceArea {
        from
        to
      }
      villaView
      hotelStar
      investor
      floorCount {
        from
        to
      }
      floorPosition {
        from
        to
      }
      bedroomCount {
        from
        to
      }
      toiletCount {
        from
        to
      }
      timeForRent {
        from
        to
      }
      projectName
      blockName
      buildingType
      factoryFunctions
      industryTrade
      hasMezzanine
      postTitle
      postContent
      privateNote
      direction
      contactName
      contactPhones
      contactEmail
      postType
      startShowTime
      endShowTime
      tradingStatus
      postStatus
      legals
      location {
        type
        coordinates
      }
      isNewly
      fixedUtilities
      utilities
      localUtilities
      equipmentsAndServices
      realEstateId
      demandEstate
      createdAt
      approvalStatus
      approvalReason
      postStatus
      approvedAt
      approvedBy {
        id
        fullName
        avatar {
          ... on FileWithFullUrls {
            originalUrl
            fileId
          }
        }
      }
      latestRenewInfo {
        id
        startTime
        endTime
        postType
        basicAmount
        vat
      }
      isDeleted
    }
  }
`;
export const GET_REAL_ESTATE_MANAGEMENT_BY_FIELD = (field = []) => gql`
  query getRealEstateManagement(
    $realEstateId: String, 
    $typeOfDemand: String,
    $realEstateType: [String],
    $houseNumber: String,
    $street: String,
    $ward: String,
    $district: String,
    $province: String,
    $country: String, 
    $width: FromTo,
    $length: FromTo, 
    $rear: FromTo,
    $area: FromTo,
    $price: FromTo,
    $floorCount: FromTo,
    $constructionArea: FromTo,
    $bedroomCount: FromTo, 
    $toiletCount: FromTo,
    $isNewConstruction: Boolean, 
    $createdAt: FromToDateInput,
    $updatedAt: FromToDateInput,
    $approvalStatus: ApprovalStatusEnumType ,
    $page:Int,
    $limit:Int 
    $approvedAt:FromToDateInput
    $approvedBy:String
    $sort: JSONObject
  ) @api(name: realEstate) {
    getRealEstateManagement(
      realEstateId: $realEstateId,
      typeOfDemand: $typeOfDemand,
      realEstateType: $realEstateType,
      houseNumber: $houseNumber,
      street: $street,
      ward: $ward,
      district: $district,
      province: $province,
      country: $country,
      width: $width,
      length: $length,
      rear: $rear,
      area: $area,
      price: $price,
      floorCount: $floorCount,
      constructionArea: $constructionArea,
      bedroomCount: $bedroomCount,
      toiletCount: $toiletCount,
      isNewConstruction: $isNewConstruction,
      createdAt: $createdAt,
      updatedAt: $updatedAt
      approvalStatus:$approvalStatus
      approvedAt: $approvedAt
      page:$page,
      limit:$limit
      approvedBy:$approvedBy
      sort:$sort
    ) {
      hasNextPage
      page
      total
      items {
        id
        ${field.join(' ')}
      }
    }
  }
  ${field.find(e => e.includes('FileAllFields')) ? FILE_ALL_FIELDS : ''}
`;
export const GET_NEED_REAL_ESTATE_MANAGEMENT_BY_FIELD = (field = []) => gql`
  query getNeededRealEstatesManagement(
    $realEstateId: String,
    $typeOfDemand: String,
    $realEstateType: [String],
    $street: String,
    $ward: String,
    $district: String,
    $province: String,
    $country: String,
    $width: FromTo,
    $length: FromTo, 
    $rear: FromTo,
    $area: FromTo,
    $price: FromTo,
    $floorCount: FromTo, 
    $constructionArea: FromTo,
    $isNewConstruction: Boolean, 
    $createdAt: FromToDateInput,
    $updatedAt: FromToDateInput,
    $approvalStatus: ApprovalStatusEnumType
    $approvedAt:FromToDateInput
    $approvedBy:String
    $sort: JSONObject
    $page:Int
    $limit:Int
  ) @api(name: realEstate) {
    getNeededRealEstatesManagement(
      realEstateId: $realEstateId,
      typeOfDemand: $typeOfDemand,
      realEstateType: $realEstateType,
      street: $street,
      ward: $ward,
      district: $district,
      province: $province,
      country: $country,
      width: $width,
      length: $length,
      rear: $rear,
      area: $area,
      price: $price,
      floorCount: $floorCount,
      constructionArea: $constructionArea,
      isNewly: $isNewConstruction,
      createdAt: $createdAt,
      updatedAt: $updatedAt
      approvalStatus:$approvalStatus
      approvedAt: $approvedAt
      approvedBy: $approvedBy
      sort: $sort
      page:$page
      limit:$limit
    ) {
      hasNextPage
      page
      total
      items {
        id
        ${field.join(' ')}
      }
    }
  }
  ${field.find(e => e.includes('FileAllFields')) ? FILE_ALL_FIELDS : ''}
`;
export const GET_REAL_ESTATE_CONFIGURATIONS = gql`
  query @api(name: realEstate) {
    getRealEstateConfigurations {
      common {
        constructionArea {
          key
          value
          from
          to
          unit
        }
        mediaTypes {
          key
          value
        }
        postTypes {
          key
          value
          fee
        }
        fixedUtilities {
          key
          value
        }
        utilities {
          key
          value
        }
        localUtilities {
          key
          value
        }
        equipmentsAndServices {
          key
          value
        }
        forRentRealEstateTypes {
          key
          value
        }
        forSellRealEstateTypes {
          key
          value
        }
        priceUnitsForRent {
          key
          value
        }
        priceUnitsForSell {
          key
          value
        }
        timeForRentUnits {
          key
          value
        }
        legals {
          estateType
          key
          value
        }
        warehouseType {
          key
          value
        }
        projectForSaleTypes {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        projectForRentTypes {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        projectSubTypes {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        projectLegals {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        projectViews {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        collectionTypes {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        villaViewTypes {
          key
          value
        }
        projectAreaType {
          key
          value
        }
        directions {
          key
          value
        }
      }
    }
  }
`;
export const GET_REAL_ESTATE = gql`
  query getRealEstates(
    $searchString: String
    $typeOfDemand: String
    $contactName: String
    $realEstateType: [String]
    $tradingStatus: [TradingStatus]
    $province: String
    $district: String
    $postStatus: [PostStatusType]
    $ward: String
    $houseNumber: String
    $isAuthorizedRe: Boolean
    $street: String
    $price: FromTo
    $priceUnit: String
    $rear: FromTo
    $area: FromTo
    $length: FromTo
    $width: FromTo
    $alleyWidth: FromTo
    $bedroomCount: FromTo
    $toiletCount: FromTo
    $company: String
    $commission: String
    $direction: [Direction]
    $floorCount: FromTo
    $realEstateId: String
    $phone: String
    $creator: String
    $creatorName: String
    $sort: JSONObject
    $hasAlley: Boolean
    $createdAt: FromToDateInput
    $updatedAt: FromToDateInput
    $page: Int
    $limit: Int
    $projectIds: [String]
    $isProduct: Boolean
    $approvedAt: FromToDateInput
    $approvedBy: String
    $ownerName: String
    $ownerPhone: String
    $constructionArea: FromTo
    $frontHomeAlleyWidth: FromTo
  ) @api(name: realEstate) {
    getRealEstates(
      searchString: $searchString
      creator: $creator
      creatorName: $creatorName
      houseNumber: $houseNumber
      isAuthorizedRe: $isAuthorizedRe
      contactName: $contactName
      postStatus: $postStatus
      typeOfDemand: $typeOfDemand
      realEstateType: $realEstateType
      tradingStatus: $tradingStatus
      province: $province
      district: $district
      street: $street
      price: $price
      priceUnit: $priceUnit
      area: $area
      length: $length
      width: $width
      rear: $rear
      alleyWidth: $alleyWidth
      bedroomCount: $bedroomCount
      toiletCount: $toiletCount
      company: $company
      commission: $commission
      direction: $direction
      floorCount: $floorCount
      realEstateId: $realEstateId
      phone: $phone
      sort: $sort
      hasAlley: $hasAlley
      createdAt: $createdAt
      updatedAt: $updatedAt
      page: $page
      limit: $limit
      ward: $ward
      projectIds: $projectIds
      isProduct: $isProduct
      approvedAt: $approvedAt
      approvedBy: $approvedBy
      ownerName: $ownerName
      ownerPhone: $ownerPhone
      constructionArea: $constructionArea
      frontHomeAlleyWidth: $frontHomeAlleyWidth
    ) {
      total
      totalPages
      items {
        constructionArea
        id
        realEstateType
        demandEstate
        realEstateId
        isForSell
        type
        postStatus
        province {
          id
          name
        }
        provinceName
        provinceId
        district {
          id
          name
        }
        districtId
        ward {
          id
          name
        }
        wardId
        street {
          id
          name
        }
        map {
          type
          coordinates
        }
        location {
          type
          coordinates
        }
        frontHomeAlleyWidth
        constructionArea
        direction
        commission
        floorCount
        bedroomCount
        toiletCount
        streetId
        streetName
        houseNumber
        contactName
        ownerName
        ownerPhone
        width
        length
        rear
        area
        floorCount
        price
        priceUnit
        createdAt
        updatedAt
        approvedAt
        approvedBy {
          id
          fullName
          avatar {
            ... on FileWithFullUrls {
              fileId
              originalUrl
            }
          }
        }
        creator {
          id
          fullName
          avatar {
            ... on FileWithFullUrls {
              fileId
              originalUrl
            }
          }
        }
      }
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
`;

export const GET_PREMIUM_REAL_ESTATES = gql`
  query (
    $typeOfDemand: String
    $realEstateType: [String]
    $province: String
    $district: String
    $ward: String
    $street: String
    $price: FromTo
    $area: FromTo
    $length: FromTo
    $width: FromTo
    $rear: FromTo
    $floorCount: FromTo
    $direction: [Direction]
    $phone: String
    $postStatus: PostStatusType
    $tradingStatus: TradingStatus
    $page: Int
    $limit: Int
    $sort: String
    $rangeOfDate: FromTo
    $approvalStatus: ApprovalStatusEnumType
  ) @api(name: realEstate) {
    getPremiumRealEstates(
      typeOfDemand: $typeOfDemand
      realEstateType: $realEstateType
      province: $province
      district: $district
      ward: $ward
      street: $street
      price: $price
      area: $area
      length: $length
      width: $width
      rear: $rear
      floorCount: $floorCount
      direction: $direction
      phone: $phone
      postStatus: $postStatus
      tradingStatus: $tradingStatus
      page: $page
      limit: $limit
      sort: $sort
      rangeOfDate: $rangeOfDate
      approvalStatus: $approvalStatus
    ) {
      total
      items {
        id
        isForSell
        realEstateId
        demandEstate
        type
        typeString
        width
        length
        rear
        area
        alleyWidth
        price
        priceUnit
        direction
        creator {
          id
          type
          fullName
        }
        toiletCount
        province {
          id
          name
        }
        provinceName
        district {
          id
          name
        }
        ward {
          id
          name
        }
        street {
          id
          name
        }
        creator {
          id
          fullName
          avatar {
            ... on FileWithFullUrls {
              fileId
              originalUrl
            }
          }
        }
        approvedAt
        approvedBy {
          id
          type
          fullName
          gender
          phone
          province
          memberId
          isPremiumAccount
          email
        }
        ownerName
        streetName
        houseNumber
        floorCount
        createdAt
        updatedAt
        bedroomCount
      }
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
`;

export const REAL_ESTATE_HISTORY = gql`
  query realEstateHistory(
    $type: RealEstateEnumType!
    $historyType: RealEstateHistoryType!
    $realEstateId: ObjectID!
    $page: Int
    $limit: Int
    $fromDate: Date
    $toDate: Date
  ) @api(name: realEstate) {
    realEstateHistory(
      type: $type
      historyType: $historyType
      realEstateId: $realEstateId
      page: $page
      limit: $limit
      fromDate: $fromDate
      toDate: $toDate
    ) {
      hasNextPage
      page
      items {
        id
        orderId
        logCreator {
          id
          fullName
          avatar {
            ... on FileWithFullUrls {
              fileId
              originalUrl
            }
          }
        }
        ops
        date
        historyType
        renewDetails {
          postType
          startTime
          endTime
          basicAmount
          vat
          approvedBy {
            id
            fullName
            avatar {
              ... on FileWithFullUrls {
                fileId
                originalUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const REAL_ESTATE_ON_MAP_V3 = gql`
  query (
    $phone: String
    $bottomLeft: MapDataInput!
    $upperRight: MapDataInput!
    $circle: CircleInput
    $polygonPoints: [MapDataInput] = []
    $searchString: String
    $typeOfDemand: String
    $realEstateType: [String]
    $utilities: [String]
    $province: String
    $district: String
    $ward: String
    $street: String
    $price: FromTo
    $area: FromTo
    $length: FromTo
    $width: FromTo
    $rear: FromTo
    $floorCount: FromTo
    $bedroomCount: FromTo
    $toiletCount: FromTo
    $bedRoomArea: FromTo
    $floorPosition: FromTo
    $roomCount: FromTo
    $blockCount: FromTo
    $alleyWidth: FromTo
    $typeOfRealEstate: [String]
    $hasAlley: Boolean
  ) @api(name: realEstate) {
    realEstateOnMapV3(
      filter: {
        phone: $phone
        circle: $circle
        polygonPoints: $polygonPoints
        bottomLeft: $bottomLeft
        upperRight: $upperRight
        searchString: $searchString
        typeOfDemand: $typeOfDemand
        realEstateType: $realEstateType
        typeOfRealEstate: $typeOfRealEstate
        province: $province
        district: $district
        ward: $ward
        street: $street
        price: $price
        area: $area
        length: $length
        width: $width
        rear: $rear
        floorCount: $floorCount
        bedroomCount: $bedroomCount
        toiletCount: $toiletCount
        bedRoomArea: $bedRoomArea
        floorPosition: $floorPosition
        roomCount: $roomCount
        blockCount: $blockCount
        alleyWidth: $alleyWidth
        utilities: $utilities
        hasAlley: $hasAlley
      }
    ) {
      id
      count
      lat
      long
      bottomLeft {
        type
        coordinates
      }
      upperRight {
        type
        coordinates
      }
    }
  }
`;

export const GET_REAL_ESTATE_BY_FIELD = (field = []) => gql`
  query getRealEstates(
    $creator: String
    $searchString: String
    $typeOfDemand: String
    $kindOfRealEstate: [String]
    $realEstateType: [String]
    $province: String
    $district: String
    $ward: String
    $street: String
    $price: FromTo
    $area: FromTo
    $length: FromTo
    $width: FromTo
    $rear: FromTo
    $floorCount: FromTo
    $bedroomCount: FromTo
    $toiletCount: FromTo
    $bedRoomArea: FromTo
    $floorPosition: FromTo
    $roomCount: FromTo
    $blockCount: FromTo
    $alleyWidth: FromTo
    $phone: String
    $direction: [Direction]
    $sort: JSONObject
    $isAuthorizedRe: Boolean
    $isSaleOffRE: Boolean
    $mediaTypes: [String]
    $utilities: [String]
    $hasSeaView: Boolean
    $hotelStar: Float
    $hasAlley: Boolean
    $villaView: [String]
    $projectName: String
    $page: Int
    $limit: Int
    $postStatus: [PostStatusType]
    $tradingStatus: [TradingStatus]
    $postType: [String]
    $buildingType: String
    $filterOnMap: MapCoordinates
    $createdAt: FromToDateInput
    $ids: [ObjectID]
    $approvalStatus: ApprovalStatusEnumType
    $startShowTime: FromTo
    $except:ObjectID
  ) @api(name: realEstate) {
    getRealEstates(
      ids: $ids
      creator: $creator
      searchString: $searchString
      kindOfRealEstate: $kindOfRealEstate
      typeOfDemand: $typeOfDemand
      realEstateType: $realEstateType
      province: $province
      district: $district
      ward: $ward
      street: $street
      price: $price
      area: $area
      length: $length
      width: $width
      rear: $rear
      floorCount: $floorCount
      bedroomCount: $bedroomCount
      toiletCount: $toiletCount
      bedRoomArea: $bedRoomArea
      floorPosition: $floorPosition
      roomCount: $roomCount
      blockCount: $blockCount
      alleyWidth: $alleyWidth
      phone: $phone
      direction: $direction
      sort: $sort
      isAuthorizedRe: $isAuthorizedRe
      isSaleOffRE: $isSaleOffRE
      mediaTypes: $mediaTypes
      utilities: $utilities
      hasSeaView: $hasSeaView
      hotelStar: $hotelStar
      hasAlley: $hasAlley
      villaView: $villaView
      projectName: $projectName
      page: $page
      limit: $limit
      postStatus: $postStatus
      tradingStatus: $tradingStatus
      postType: $postType
      buildingType: $buildingType
      filterOnMap: $filterOnMap
      createdAt: $createdAt
      approvalStatus: $approvalStatus
      startShowTime: $startShowTime
      except:$except
    ) {
      total
      items {
        id
        ${field.join()}
      }
      limit
      page
      totalPages
      hasNextPage
    }
  }
  ${field.find(e => e.includes('FileAllFields2')) ? MEDIA_UNION : ''}
`;
