import React, { useState } from 'react';
import { useUploadImage } from '@utils/hooks/common/useUploadImage';
import { useDropzone } from 'react-dropzone';
import MyButton from '@components/button';
import { getNameUrlFromSever } from '@utils/helper';
import { Controller, useFormContext } from 'react-hook-form';
import UploadErrorModal from './UploadErrorModal';
import MyModalV2 from '@components/modalV2/myModalV2';
import ViewFileModal from '../../viewFileModal/viewFileModal';
import { DocumentsMaxSize2MB } from 'constant';

type Props = {
  attachments?: Array,
  onChange?: Function,
  isEdit?: boolean,
  maxFile?: number,
};
const headerClass =
  'flex h-full px-2.5 text-black-main text-14 font-medium border-l border-gray-300 items-center truncate';
function RenderHeader() {
  return (
    <div
      style={{ backgroundColor: '#EDF4FF' }}
      className="w-full h-50px flex flex-row items-center overflow-x-auto"
    >
      <div
        className={`w-60px flex h-full px-2.5 text-black-main text-14 font-medium items-center truncate`}
      >
        STT
      </div>
      <div className={`flex-1 ${headerClass}`}>Tên tài liệu</div>
      <div className={`w-220px ${headerClass}`}>Tiến trình tải lên</div>
      <div className={`w-142px ${headerClass}`}> </div>
    </div>
  );
}

type ItemRowProps = {
  index?: number,
  item?: Object,
  onRemove?: Function,
};

function ItemRow({ item, index, onRemove }: ItemRowProps) {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="h-50px flex flex-row items-center">
      <div className="flex w-60px h-full items-center justify-center border-t border-gray-300 text-black-main text-14">
        {index + 1}
      </div>
      <div
        onClick={() => setShowModal(true)}
        className={`flex-1 overflow-hidden flex flex-row items-center space-x-1 ${headerClass} border-t`}
      >
        <img src="/svg/paperclip-icon.svg" alt="paperclip icon" />
        <div
          style={{
            color: '#106FFF',
          }}
          className="text-14 font-medium underline cursor-pointer truncate"
        >
          {getNameUrlFromSever(item?.originalUrl || item?.name)}
        </div>
      </div>
      <div
        style={{ color: item?.loading ? '#80888F' : '#40D124' }}
        className={`w-220px ${headerClass} border-t`}
      >
        <div className="flex flex-row">
          {item?.loading ? 'Đang tải lên' : 'Hoàn thành'}
        </div>
      </div>
      <div className={`w-142px ${headerClass} border-t justify-center`}>
        <img
          src="/svg/recycle-bin-icon.svg"
          onClick={onRemove}
          className="cursor-pointer"
          alt="Xóa icon"
        />
      </div>
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ViewFileModal fileId={item?.fileId} />
      </MyModalV2>
    </div>
  );
}

type DocumentTableProps = {
  attachments?: Array,
  onRemove?: Function,
};
function DocumentTable({ attachments, onRemove }: DocumentTableProps) {
  return (
    <div className="w-full border border-gray-300 rounded-md overflow-hidden">
      <RenderHeader />
      {attachments?.map((item, index) => {
        return (
          <ItemRow
            onRemove={() => onRemove(item?.fileId)}
            item={item}
            key={index}
            index={index}
          />
        );
      })}
    </div>
  );
}

function UploadPanel({ attachments, onChange, isEdit, maxFile = 6 }: Props) {
  const [showUploadErr, setShowUploadErr] = useState(false);
  const { doUploadImage, removeFile } = useUploadImage({
    mediaUrls: attachments,
    setMediaUrls: onChange,
    setOpenModal: () => {},
    isEdit,
    fieldName: 'attachments',
  });
  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      'image/*',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/pdf',
      'application/xhtml+xml',
      'application/xml',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/octet-stream',
    ],
    multiple: true,
    onDrop: async files => {
      let errorFiles = '';
      for (let i = 0; i < files.length; i++) {
        if (files[i]?.size / DocumentsMaxSize2MB > 1) {
          errorFiles += `${files[i]?.name}, `;
        }
      }
      errorFiles.length > 0 && setShowUploadErr(true);
      files = files.filter(f => f?.size / DocumentsMaxSize2MB < 1);
      if (files?.length + attachments?.length > 6) {
        setShowUploadErr(true);
        return;
      }
      files?.length > 0 && doUploadImage(files);
    },
    disabled: attachments?.length >= maxFile,
  });
  return (
    <div className="w-full space-y-7">
      <div className="w-full flex flex-col space-y-4 items-center text-gray-400 text-sm">
        <span className="flex flex-row">
          {`Bấm vào nút "Chọn tệp tải lên" để đăng tải tệp`}
        </span>
        <span className="flex flex-row">
          Lưu ý: Mỗi tệp không quá 2MB, tối đa 6 tệp
        </span>
        <div {...getRootProps({ className: 'dropzone' })} className="w-176px">
          <input {...getInputProps()} style={{ display: 'none' }} />
          <MyButton
            disabled={attachments?.length >= maxFile}
            leftComponent={() => (
              <img src="/svg/plus-blue-icon.svg" alt="Thêm icon" />
            )}
            type="other"
            label="Chọn tệp tải lên"
          />
        </div>
      </div>
      {attachments?.length > 0 && (
        <DocumentTable onRemove={removeFile} attachments={attachments} />
      )}
      <MyModalV2 onClose={() => setShowUploadErr(false)} open={showUploadErr}>
        <UploadErrorModal onClose={() => setShowUploadErr(false)} />
      </MyModalV2>
    </div>
  );
}
export default function ContractDocuments({ isEdit }: Props) {
  const { control, watch } = useFormContext();
  const attachments = watch('attachments');
  return (
    <div className="flex flex-1 rounded-lg px-4 py-5 space-x-6">
      <div className="w-full space-y-6 ">
        <div className="font-medium text-base">
          Tài liệu hợp đồng đính kèm ({attachments?.length}/6)
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Controller
            control={control}
            name="attachments"
            render={({ field: { onChange, value } }) => {
              return (
                <div className="w-full space-y-4">
                  <UploadPanel
                    isEdit={isEdit}
                    onChange={onChange}
                    attachments={value}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
