import MyButton from '@components/button';
import React, { useState } from 'react';
import { useDropzone as Drop } from 'react-dropzone';
import { useUploadImage } from '@utils/hooks/common/useUploadImage';
import FilesResult from '@components/fileResult';
import UploadErrorModal from '../modals/uploadError';
import { DocumentsMaxSize2MB } from 'constant';

type Props = {
  mediaUrls?: Array,
  onChange?: Function,
  isEdit?: boolean,
  maxFile?: number,
};
export default function UploadPanel({
  mediaUrls,
  onChange,
  isEdit,
  maxFile = 6,
}: Props) {
  const [isShowModal, setShowModal] = useState(false);
  const { doUploadImage, removeFile } = useUploadImage({
    mediaUrls,
    setMediaUrls: onChange,
    setOpenModal: () => {},
    isEdit,
  });
  const { getRootProps, getInputProps } = Drop({
    accept: ['image/*'],
    multiple: true,
    onDrop: async files => {
      let errorFiles = '';
      for (let i = 0; i < files.length; i++) {
        if (files[i]?.size / DocumentsMaxSize2MB > 1) {
          errorFiles += `${files[i]?.name}, `;
        }
      }
      errorFiles.length > 0 && setShowModal(true);
      files = files.filter(f => f?.size / DocumentsMaxSize2MB < 1);
      if (files?.length + mediaUrls?.length > 6) {
        setShowModal(true);
        return;
      }
      files?.length > 0 && doUploadImage(files);
    },
    disabled: mediaUrls?.length >= maxFile,
  });
  return (
    <div className="w-full space-y-7">
      <div className="w-full flex flex-col space-y-4 items-center text-gray-400 text-sm">
        <span className="flex flex-row">
          {`Bấm vào nút "Chọn ảnh tải lên" để đăng tải hình ảnh`}
        </span>
        <span className="flex flex-row">
          Lưu ý: Mỗi hình ảnh không quá 1MB, tối đa 6 ảnh
        </span>
        <div {...getRootProps({ className: 'dropzone' })} className="w-176px">
          <input {...getInputProps()} style={{ display: 'none' }} />
          <MyButton
            disabled={mediaUrls?.length >= maxFile}
            leftComponent={() => (
              <img src="/svg/plus-blue-icon.svg" alt="Thêm icon" />
            )}
            type="other"
            label="Chọn tệp tải lên"
          />
        </div>
      </div>
      {mediaUrls?.length > 0 && (
        <FilesResult onRemove={removeFile} imageUrls={mediaUrls} />
      )}
      <UploadErrorModal
        isOpen={isShowModal}
        onClose={() => setShowModal(false)}
        type="img"
      />
    </div>
  );
}
