import MyButton from '@components/button';
import React, { useState } from 'react';
import AdditionalContractModal from '../../additionalContractModal';
import MyModalV2 from '@components/modalV2/myModalV2';
import CreateContract from 'modals/createContractModal';

type Props = {
  listAttachment?: Array,
  contractDetail?: Object,
  contractId?: string,
  isLocked?: boolean,
  onSuccess?: Function,
  onCloseDetailModal?: Function,
  b2bUserData?: Object,
};
export default function RenderRightButton({
  listAttachment,
  contractDetail,
  contractId,
  isLocked,
  onSuccess,
  onCloseDetailModal,
  b2bUserData,
}: Props) {
  const [showAdditionalModal, setShowAdditionalModal] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);
  return (
    <div
      style={{ boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.24)' }}
      className="w-240px h-100vh ml-4 bg-white rounded-lg px-4 py-7 "
    >
      <div className="space-y-4">
        <MyButton
          disabled={isLocked}
          onPress={() => setShowContractModal(true)}
          type="blue"
          label="Hủy - Nâng cấp hợp đồng"
        />
        <MyButton
          disabled={isLocked}
          onPress={() => setShowAdditionalModal(true)}
          type="gray"
          label="Bổ sung hợp đồng"
        />
      </div>
      <MyModalV2
        open={showAdditionalModal}
        onClose={() => setShowAdditionalModal(false)}
      >
        <AdditionalContractModal
          listAttachment={listAttachment}
          note={contractDetail?.note}
          contractId={contractId}
          onClose={() => setShowAdditionalModal(false)}
        />
      </MyModalV2>
      <MyModalV2 hideClose open={showContractModal} onClose={() => {}}>
        <CreateContract
          listAttachment={listAttachment}
          b2bUserData={b2bUserData}
          onCloseDetailModal={onCloseDetailModal}
          onSuccess={onSuccess}
          contractId={contractId}
          onClose={() => {
            setShowContractModal(false);
          }}
        />
      </MyModalV2>
    </div>
  );
}
