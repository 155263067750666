import styles from '@components/table/table.module.scss';
import React from 'react';
import RenderResultFilterHeader from './renderResultFilterHeader';
import SearchTextDropdown from '../../../../../../manageRealEstate/components/filterButtons/searchTextDropdown';
import DropdownMultiSelection from '@components/dropdownMultiSelection';
import {
  contractStatusConfigs,
  contractTypeConfigs,
} from '../../../../../../../../configs';
import CalendarDropdown from '../../../../../../manageRealEstate/components/filterButtons/calendarDropdown';

type Props = {
  stateFilter?: Object,
  setStateFilter?: Function,
};
export default function RenderHeader({ stateFilter, setStateFilter }: Props) {
  return (
    <div
      className="flex-1"
      style={{
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      }}
    >
      <div
        className="w-full h-40px flex flex-row items-center"
        style={{
          backgroundColor: '#EDF4FF',
        }}
      >
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        >
          <div
            className={`text-13 font-medium text-blue-main ${styles.textHead}`}
          >
            STT
          </div>
        </div>
        <div
          style={{ maxWidth: 300 }}
          className={`${styles.headContainer} w-212px items-center`}
        >
          <SearchTextDropdown
            title="Mã hợp đồng"
            search={stateFilter.contractCode}
            setSearch={value => {
              setStateFilter('contractCode')(value);
            }}
          />
        </div>
        <div className={`${styles.headContainer} w-212px items-center`}>
          <DropdownMultiSelection
            selections={contractTypeConfigs}
            value={stateFilter?.system}
            setValue={value => setStateFilter('system')(value)}
            title="Loại hợp đồng"
          />
        </div>
        <div
          style={{ maxWidth: 300 }}
          className={`${styles.headContainer} w-212px items-center`}
        >
          <div
            className={`text-13 font-medium text-blue-main ${styles.textHead}`}
          >
            Tên hợp đồng
          </div>
        </div>
        <div className={`${styles.headContainer} w-212px items-center`}>
          <CalendarDropdown
            start={stateFilter.endDateFrom}
            end={stateFilter.endDateTo}
            setStart={value => setStateFilter('endDateFrom')(value)}
            setEnd={value => setStateFilter('endDateTo')(value)}
            title="Thời hạn hợp đồng"
            disableMaxDateFrom
          />
        </div>
        <div className={`${styles.headContainer} w-212px items-center`}>
          <DropdownMultiSelection
            selections={contractStatusConfigs}
            value={stateFilter?.status}
            setValue={value => setStateFilter('status')(value)}
            title="Trạng thái"
          />
        </div>
        <div className={`${styles.headContainer} w-212px items-center`}>
          <div
            className={`text-13 font-medium text-blue-main ${styles.textHead}`}
          >
            Số lượng nhân viên
          </div>
        </div>
      </div>
      <RenderResultFilterHeader
        stateFilter={stateFilter}
        setStateFilterAt={setStateFilter}
      />
    </div>
  );
}
