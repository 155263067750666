import { useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { pickBy } from 'lodash';
import { GET_LIST_CONTACTS } from '@utils/graphql';
import { useLoadMore } from '@utils/hooks/common/useLoadMore';
import moment from 'moment/moment';
export const useGetListB2BContract = ({ userId, state }) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { contractCode, status, endDateFrom, endDateTo } = state;
  const variables = useMemo(() => {
    return pickBy(
      {
        contractCode,
        status:
          state?.status?.length === 0 || state?.status?.length === 2
            ? null
            : state?.status,
        date:
          endDateFrom && endDateTo
            ? {
                from: moment.unix(endDateFrom).format('YYYY-MM-DD'),
                to: moment.unix(endDateTo).format('YYYY-MM-DD'),
              }
            : null,
        system:
          state?.system?.length === 0 || state?.system?.length === 2
            ? null
            : state?.system[0],
      },
      e => e
    );
  }, [contractCode, status, endDateFrom, endDateTo, state]);
  const finalVariables = {
    idAdmin: userId,
    ...variables,
    page: 1,
    limit: 10,
  };
  const { data, loading, fetchMore } = useQuery(GET_LIST_CONTACTS, {
    variables: finalVariables,
    skip: !userId,
  });

  const listContract = data?.getListContracts?.items || [],
    total = data?.getListContracts?.total || 0,
    totalPage = data?.getListContracts?.totalPages || 0,
    page = data?.getListContracts?.page || 1;
  const hasNextPage = data?.getListContracts?.hasNextPage;
  const onLoadMore = useLoadMore({
    isLoadingMore,
    setIsLoadingMore,
    variables: finalVariables,
    fetchMore,
    currentPage: page,
    hasNextPage,
  });

  return {
    loading,
    listContract,
    total,
    totalPage,
    onLoadMore,
    isLoadingMore,
    hasNextPage,
  };
};
