import React, { useState } from 'react';
import MyButton from '@components/button';
import ConfirmLockUnlockAccount from '../../../../../../../modals/confirmLockUnlockModal';
import { useLockUnlockB2BUser } from '@utils/hooks/manageUserB2B/useLockUnlockB2BUser';
import { useNavigate } from 'react-router-dom';
import ContractDetailModal from '../../../../../../../modals/contractDetailModal';
import CreateContract from 'modals/createContractModal';
import SuccessModal from 'modals/successModal';
import MyModalV2 from '@components/modalV2/myModalV2';
import EditUserHistoryModal from '../../../../../../../modals/editUserHistoryModal';
import MyToast from '@components/MyToast';
import { useToast } from '@chakra-ui/react';
import AssignAdminPermissions from '../../../../../../../modals/assignAdminPermissionsModal';

type Props = {
  setStatus?: Function,
  setShowModal?: Function,
  isLocked?: boolean,
  userId?: string,
  adminData?: Object,
  tab?: string,
  contractId?: string,
  contract?: Object,
  isPersonal?: boolean,
  b2bUserData?: Object,
};

export default function RightUserDetail(props: Props) {
  const {
    isLocked,
    userId,
    tab,
    contractId,
    isPersonal,
    b2bUserData,
    contract,
    adminData,
  } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const [showModal, setShowModal] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [showContractDetailModal, setShowContractDetailModal] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showLockContractModal, setShowLockContractModal] = useState(false);
  const [showAdminPermissionsModal, setShowAdminPermissionsModal] =
    useState(false);
  const { onSubmit, loading } = useLockUnlockB2BUser(
    userId,
    setShowModal,
    isLocked
  );
  return (
    <div
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)' }}
      className="w-240px h-100vh ml-4 bg-white rounded-lg px-4 py-7 "
    >
      {tab === '0' ? (
        <div className="space-y-4">
          <MyButton
            disabled={isLocked}
            onPress={() => navigate(`/editB2BUserAccount/${userId}`)}
            type="gray"
            label="Chỉnh sửa thông tin"
          />
          <EditUserHistoryButton isPersonal={isPersonal} userId={userId} />
          <MyButton
            onPress={() => setShowModal(true)}
            type="gray"
            label={!isLocked ? 'Khóa tài khoản' : 'Mở khóa tài khoản'}
          />
        </div>
      ) : (
        <div className="space-y-4">
          <MyButton
            disabled={isLocked}
            rightComponent={() => (
              <img alt="plus-icon" src="/svg/plus-icon.svg" />
            )}
            onPress={() => {
              setShowContractModal(true);
              setIsNew(true);
            }}
            type="blue"
            label="Thêm hợp đồng"
          />
          <MyButton
            disabled={!contractId}
            onPress={() => setShowContractDetailModal(true)}
            type="gray"
            label="Chi tiết hợp đồng"
          />
          <MyButton
            disabled={!contractId || isLocked || contract?.status === 'expired'}
            onPress={() => setShowAdminPermissionsModal(true)}
            type="gray"
            label="Cấp quyền"
          />
          <MyButton
            disabled={!contractId}
            onPress={() =>
              toast({
                position: 'bottom-right',
                duration: 5000,
                render: ({ id, onClose }) => (
                  <MyToast
                    id={id}
                    onClose={onClose}
                    type="warning"
                    message="Chức năng đang được hoàn thiện"
                    closeButtonPos="center"
                  />
                ),
              })
            }
            type="gray"
            label={'Lịch sử chỉnh sửa <br/> bộ quyền'}
          />
          <MyButton
            disabled={!contractId}
            onPress={() => setShowLockContractModal(true)}
            type="gray"
            label={'Khóa hợp đồng'}
          />
        </div>
      )}
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ConfirmLockUnlockAccount
          loading={loading}
          onConfirm={onSubmit}
          header={!isLocked ? 'Khóa tài khoản' : 'Mở khóa tài khoản'}
          question={`Bạn có chắc muốn ${
            isLocked ? 'mở khóa' : 'khóa'
          } tài khoản này?`}
          content={
            isLocked
              ? 'Sau khi được mở khóa, người dùng sẽ có thể đăng nhập vào hệ thống.'
              : 'Sau khi bị khóa người dùng sẽ không thể đăng nhập vào hệ thống được nữa'
          }
          onClose={() => setShowModal(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showLockContractModal}
        onClose={() => setShowLockContractModal(false)}
      >
        <ConfirmLockUnlockAccount
          loading={loading}
          header={'Khóa hợp đồng'}
          question={`Bạn có chắc muốn khóa hợp đồng này?`}
          content={
            'Sau khi bị khóa người dùng sẽ không thể huỷ-nâng cấp hợp đồng hoặc bổ sung hợp đồng'
          }
          onClose={() => setShowLockContractModal(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showContractDetailModal}
        onClose={() => setShowContractDetailModal(false)}
      >
        <ContractDetailModal
          isLocked={isLocked}
          contractId={contractId}
          onClose={() => setShowContractDetailModal(false)}
          b2bUserData={b2bUserData}
          onSuccess={() => {
            setShowSuccessModal(true);
          }}
        />
      </MyModalV2>
      <MyModalV2 hideClose open={showContractModal} onClose={() => {}}>
        <CreateContract
          isNew={isNew}
          contractId={contractId}
          tab={tab}
          b2bUserData={b2bUserData}
          idAdmin={userId}
          onClose={() => {
            setShowContractModal(false);
          }}
          onSuccess={() => {
            setShowSuccessModal(true);
          }}
        />
      </MyModalV2>
      <MyModalV2
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      >
        <SuccessModal
          contentTop="Thành công !"
          contentBottom={
            tab === '1'
              ? 'Tạo hợp đồng BĐS riêng lẻ thành công.'
              : 'Tạo hợp đồng BĐS dự án thành công.'
          }
          contentButton="Xác nhận"
          onClose={() => setShowSuccessModal(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showAdminPermissionsModal}
        onClose={() => setShowAdminPermissionsModal(false)}
      >
        <AssignAdminPermissions
          adminData={adminData}
          adminId={userId}
          onClose={() => setShowAdminPermissionsModal(false)}
          system={contract?.system}
        />
      </MyModalV2>
    </div>
  );
}

type EditUserHistoryButtonProps = {
  userId?: string,
  isPersonal?: boolean,
};

function EditUserHistoryButton({
  userId,
  isPersonal,
}: EditUserHistoryButtonProps) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <MyButton
        onPress={() => setShowModal(true)}
        type="gray"
        label="Lịch sử chỉnh sửa"
      />
      <MyModalV2
        className="w-full"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <EditUserHistoryModal
          isPersonal={isPersonal}
          userId={userId}
          onClose={() => setShowModal(false)}
        />
      </MyModalV2>
    </>
  );
}
