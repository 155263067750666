import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export default function Authentication() {
  const navigate = useNavigate();
  const token = localStorage.getItem('silverBullet');
  useEffect(() => {
    if (token?.length) {
      navigate('/manageRealEstate/needApproval');
    } else {
      navigate('/auth/login');
    }
  }, [token]);
  return (
    <div className="flex-1 flex flex-row h-screen">
      <Outlet />
      <div className="flex-1">
        <img
          style={{ backgroundSize: 'cover' }}
          alt="auth-banner"
          src="/svg/authBanner.svg"
          className="object-cover w-full h-full"
        />
      </div>
    </div>
  );
}
