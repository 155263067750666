import React, { useCallback, useEffect } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { alpha, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Checkbox from '@components/checkbox';
import { Permission } from '@gugotech/tncc-users-permissions';

const useStyles = makeStyles(theme => ({
  treeItem: {
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      // paddingLeft: 18,
      borderLeft: `1px solid ${alpha(theme.palette.text.secondary, 0.4)}`,
    },
    [`& .${treeItemClasses.content} .MuiTreeItem-root`]: {
      borderBottom: `1px solid ${alpha(theme.palette.text.secondary, 0.4)}`,
    },
  },
}));

type Props = {
  permissions?: Array,
  checkAll?: boolean,
  setPartialCheck?: Function,
  selected?: Array,
  setSelected?: Function,
  system?: string,
};
export default function RecursiveTreeView({
  permissions,
  checkAll,
  setPartialCheck,
  selected,
  setSelected,
  system,
}: Props) {
  const allPermissions = Permission.getAllPermissionsBySystem(system);
  useEffect(() => {
    if (checkAll) {
      setSelected(allPermissions?.map(p => p.id));
    } else {
      setSelected([]);
    }
  }, [checkAll, setSelected]);
  //node is always the root "Parent"
  //id is id of node clicked
  function getChildById(nodes, id) {
    let array = [];

    //returns an array of nodes ids: clicked node id and all children node ids
    function getAllChild(nodes) {
      if (nodes === null) return [];
      array.push(nodes?.id);
      if (Array.isArray(nodes?.children)) {
        nodes?.children.forEach(node => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    //returns the node object that was selected
    function getNodeById(nodes, id) {
      if (nodes.id === id) {
        return nodes;
      } else if (Array.isArray(nodes?.children)) {
        let result = null;
        nodes?.children.forEach(node => {
          if (getNodeById(node, id)) {
            result = getNodeById(node, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(nodes, id));
  }

  const RenderTreeWithCheckboxes = (nodes, pos) => {
    const classes = useStyles();
    const getOnChange = useCallback(
      nodes => {
        //gets all freshly selected or unselected nodes
        const allNode = getChildById(nodes, nodes.id);
        const checked = selected?.some(e => e === nodes?.id);
        //combines newly selected nodes with existing selection
        //or filters out newly deselected nodes from existing selection
        let array = !checked
          ? [...selected, ...allNode]
          : selected.filter(value => !allNode.includes(value));
        if (selected?.length === allPermissions?.length) {
          checkAll = true;
        } else if (selected?.length > 0) {
          setPartialCheck(true);
        }
        setSelected(array);
      },
      [selected, setSelected, setPartialCheck, checkAll, allPermissions]
    );
    return (
      <TreeItem
        className={classes.treeItem}
        key={nodes?.id}
        nodeId={nodes?.id}
        label={
          <FormControlLabel
            control={
              <Checkbox
                isChecked={selected.some(item => item === nodes?.id)}
                onPress={() => {
                  getOnChange(nodes);
                }}
              />
            }
            className="text-sm"
            label={<>{`${pos}. ${nodes?.name}`}</>}
            key={nodes?.id}
          />
        }
      >
        {Array.isArray(nodes?.children)
          ? nodes?.children.map((node, index) =>
              RenderTreeWithCheckboxes(node, `${pos}.${index + 1}`)
            )
          : null}
      </TreeItem>
    );
  };

  return (
    <>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        // defaultExpanded={['0', '3', '4']}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {permissions?.map((permission, index) =>
          RenderTreeWithCheckboxes(permission, index + 1)
        )}
      </TreeView>
    </>
  );
}
