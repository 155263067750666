import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { blankState, stepConfigs } from './configs';
import RenderBottom from './components/renderBottom';
import { Outlet, useLocation } from 'react-router-dom';
import StepComponent from '@components/step';
import qs from 'query-string';
export default function CreateB2BRealEstate() {
  const form = useForm({
    defaultValues: {
      ...blankState,
    },
    mode: 'onChange',
  });
  const isCompleted = form.watch('isCompleted');
  const location = useLocation();
  const { isEdit, id } = qs.parse(location.search);
  return (
    <FormProvider {...form}>
      <div className="w-full h-full mt-1 bg-white px-4 pt-2.5 space-y-2">
        <div className="w-full h-96px bg-white flex justify-center items-center relative">
          <div className="flex flex-col space-y-2.5 absolute left-0">
            <b className="font18x text-black-main font-medium">
              {isEdit ? 'Chỉnh sửa sản phẩm' : 'Thêm sản phẩm'}
            </b>
            <label className="font14x text-black-main">
              <b>Kho sản phẩm/</b>{' '}
              {isEdit ? 'Chỉnh sửa sản phẩm' : 'Thêm sản phẩm'}
            </label>
          </div>
          <StepComponent
            isCompleted={isCompleted || isEdit}
            stepConfigs={stepConfigs}
          />
        </div>
        <div
          style={{
            backgroundColor: '#f4f4f4',
            height: location.pathname.includes('check-product') ? 715 : '100%',
          }}
          className="w-full h-full rounded-lg px-4 py-7"
        >
          <Outlet />
        </div>
        <RenderBottom key={isCompleted} isEdit={isEdit} id={id} />
      </div>
    </FormProvider>
  );
}
