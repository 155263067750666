import React, { useState } from 'react';
import { View } from 'react-native-web';
import { unionBy } from 'lodash';
import { useList } from 'react-use';
import { ManageUserB2BContext } from './context';
import Switch from '@components/switch';
import { useStateFilterManageUserB2B } from '@utils/hooks/manageUserB2B/useStateFilterManageUserB2B';
import RenderTopTable from '../components/renderTopTable';
import UserB2BTable from './components/userB2BTable';
import { useGetListB2BAdmin } from '@utils/hooks/manageUserB2B/useGetListB2BAdmin';
import UserDetail from './userDetail';

export default function ManageUserB2B() {
  const [tab, setTab] = useState('/manageUser/b2b');
  const [listSelected, setListSelected] = useList([]);
  const {
    state: stateFilter,
    setStateAt: setStateFilterAt,
    setState,
  } = useStateFilterManageUserB2B();
  const { listUser, onLoadMore, loading, isLoadingMore, hasNextPage, total } =
    useGetListB2BAdmin({ state: stateFilter });
  return (
    <ManageUserB2BContext.Provider
      value={{
        stateFilter,
        setStateFilterAt,
        setState,
        listSelected: unionBy(listSelected, e => e),
        setListSelected,
        tab,
        setTab,
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            marginTop: 4,
            backgroundColor: 'white',
            paddingHorizontal: 16,
            paddingTop: 20,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          <Switch
            value={tab}
            setValue={value => {
              setTab(value);
            }}
            configs={[
              { title: 'Danh sách khách hàng', pathname: '/manageUser/b2b' },
            ]}
          />
        </View>
        <View style={{ width: '100%', height: '100%' }}>
          <View
            style={{
              width: '100%',
              backgroundColor: 'white',
              paddingBottom: 12,
            }}
          >
            <div className="px-4 pt-2">
              <RenderTopTable
                title="Danh sách khách hàng"
                total={total}
                currentTotal={listUser?.length}
              />
            </div>
            <div className="w-full px-4">
              <UserB2BTable
                data={listUser}
                onLoadMore={onLoadMore}
                isLoadingMore={isLoadingMore}
                loading={loading}
                hasNextPage={hasNextPage}
              />
            </div>
          </View>
          {listSelected?.length === 1 ? (
            <View
              style={{
                width: '100%',
                height: '100%',
                marginTop: 8,
              }}
            >
              <UserDetail />
            </View>
          ) : (
            <div className="p-4 text-black-main">
              Vui lòng chọn 1 khách hàng để xem chi tiết
            </div>
          )}
        </View>
      </View>
    </ManageUserB2BContext.Provider>
  );
}
